@use '../base/vars'as *;
@use "sass:math";


// SANNYOKIKI-29 【コーディング】12/7 ZMシリーズ修正
.ContactLinkArea,
.lead-obi {
    background: $green;
}

.ContactLinkArea .AreaLink a {
    color: $green;
}

.ContactLinkArea .AreaLink a svg {
    position: absolute;
    width: 35px;
    left: 19px;
    top: 50%;
    transform: translateY(-50%);

    @include media(sm) {
        width: 42px;
        left: 30px;
    }
}
