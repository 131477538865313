@use '../../../base/vars'as *;

.category-page {
    .category-page__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .category-page__entries {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: rem(20);
        margin-top: rem(58);
        padding: 0 rem(20);
        box-sizing: border-box;

        @include media(sm) {
            padding: 0;
            max-width: rem(880);
        }
    }

    .category-page__pagenation {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: rem(40);
    }
}
