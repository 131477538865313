@use '../base/vars'as *;

.page-numbers {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    font-size: rem(16);
    font-weight: 700;
    margin: 0;

    @include media(sm) {
        font-size: rem(20);
    }
}

.page-numbers li {
    margin: 0 rem(4);

    @include media(sm) {
        margin: 0 rem(5);
    }
}

.page-numbers a,
.page-numbers span {
    display: block;
    padding: rem(5) rem(8);
    text-decoration: none;
    color: #000;
    background-color: $white;
    transition: background-color 0.3s ease, color 0.3s ease;

    @include media(sm) {
        padding: rem(10) rem(15);
    }
}

.page-numbers a:hover {
    opacity: .7;
}

.page-numbers .current {
    background-color: $red;
    color: $white;
    border-color: $red;
}

.page-numbers .dots {
    padding: rem(10) rem(15);
    color: $black;
    background: none;
    border: none;
}

/* Add styles for first and last buttons */
.page-numbers .first,
.page-numbers .last {
    font-weight: bold;
}
