@use '../../base/vars'as *;

html {
    scroll-behavior: smooth;
}

//*------------------------------------*//
//    変数設定
//*------------------------------------*//
$roboto: 'Roboto Condensed',
sans-serif;
$zenKaku_antique: 'Zen Kaku Gothic Antique',
sans-serif;
$zen_old: 'Zen Old Mincho',
serif;

$red: #D3000A;
$red02: #A20008;

$pb: 'lg';

//*------------------------------------*//
//    共通
//*------------------------------------*//
.frontLoader {
    position: relative;
    color: #fff;
    background-color: #000;
    font-size: rem(14);
    margin-top: rem(60);
    padding-top: 0 !important;
    padding-bottom: rem(60);
    padding-left: rem(10);
    padding-right: rem(10);
    background-image: url("../images/frontLoader/sp_top_bg@2x.png");
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain;

    @include media(xs){
        background-image: url("../images/frontLoader/top_bg@2x.png");
    }

    @include media($pb) {
        font-size: rem(18);
        margin-top: rem(100);
        padding-bottom: rem(200);
    }

    * {
        margin: 0;
        padding: 0;
        font-family: $zenKaku_antique;
    }

    .wrapper {
        max-width: rem(1510);
        margin: 0 auto;
    }

    .wrapperVer2 {
        max-width: rem(1530);
        margin: 0 auto;
    }

    .wrapperRight {
        position: relative;
        max-width: rem(1280);
        margin-left: auto;

        @include media($pb) {
            padding-left: rem(30);
        }
    }

    .container {
        max-width: rem(1240);
        margin: 0 auto;
    }

    .containerVer2 {
        max-width: rem(664);
        margin: 0 auto;
    }

    .containerVer3 {
        max-width: rem(1200);
        margin: 0 auto;
        padding: 0;

        @include media($pb) {
            padding: 0 rem(20);
        }
    }

    .sp_only {

        @include media($pb) {
            display: none;
        }
    }

    .pc_only {
        display: none;

        @include media($pb) {
            display: block;
        }
    }

    h2 {
        font-family: $roboto;
        font-weight: 100;
    }

    h2,
    h3,
    h4{
        text-transform: uppercase;
    }

    img {
        width: 100%;
        max-width: 100%;
        height: auto;
    }

    a{
        transition: all .5s ease;

        &:hover{
            opacity: .5;
        }
    }

    ._anchor{
        margin-top: rem(-60);
        padding-top: rem(60);

        @include media(sm){
            margin-top: rem(-100);
            padding-top: rem(100);
        }
    }
}

.frontLoader {

    .fl__con{
        padding-top: rem(40);

        @include media(xs) {
            padding-top: rem(100);
        }

        @include media(sm) {
            padding-top: rem(60);
        }
    }

    .fl__con--nav--ul{
        display: flex;
        margin-left: rem(-20);
        justify-content: space-around;

        @include media(xs){
            justify-content: end;
        }

        @include media(sm){
            margin-left: rem(-40);
        }

        li{
            padding-left: rem(20);

            @include media(sm){
                padding-left: rem(40);
            }
        }

        a{
            color: #fff;
            text-align: center;
            display: grid;
            grid-template-columns: 1fr;

            svg{
                width: rem(16);
                margin: 0 auto;
                margin-top: rem(12);

                @include media(sm){
                    width: rem(21);
                }
            }
        }

        .en{
            font-family: $roboto;
            font-size: rem(26);
            font-weight: 200;
            font-style: italic;
            text-transform: uppercase;
            letter-spacing: -0.025em;

            @include media(sm){
                font-size: rem(40);
                letter-spacing: 0.1em;
            }
        }

        .jp{
            font-family: $zenKaku_antique;
            font-size: rem(16);

            @include media(sm){
                font-size: rem(18);
            }
        }
    }

    .section__Title {
        padding-bottom: rem(86);
    }

    .section__TitleVer2 {
        padding-bottom: rem(398);
    }

    .section__Title,
    .section__TitleVer2 {
        position: relative;
        background-color: $red02;
        margin-right: calc(50% - 50vw);
        margin-left: calc(50% - 50vw);
        padding-right: calc(50vw - 50%);
        padding-left: calc(50vw - 50%);
        position: relative;

        @include media($pb) {
            padding-bottom: 0;
        }

        .container {

            @include media($pb) {
                position: relative;
            }

            .section__Title--inner,
            .section__Title--innerVer2 {
                margin-right: calc(50% - 50vw);

                .en {
                    position: absolute;
                    top: rem(-30);
                    left: rem(10);
                    font-size: rem(60);
                    font-style: italic;
                    font-weight: 100;
                    line-height: 1.1;
                    font-family: $roboto;

                    @include media($pb) {
                        font-size: rem(100);
                        top: rem(-78);
                        line-height: normal;
                    }
                }

                .jp {
                    font-size: rem(15);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-left: rem(13);

                    @include media($pb) {
                        font-size: rem(24);
                        padding-left: rem(20);
                    }
                }

                ._b {
                    font-size: rem(21);
                    line-height: 1.8;
                    margin-top: rem(20);

                    @include media($pb) {
                        font-size: rem(32);
                    }
                }

                ._n {
                    font-size: rem(14);
                    margin-top: rem(15);

                    @include media($pb) {
                        font-size: rem(20);
                        margin-top: rem(26);
                    }
                }

                .section__Title--line {
                    display: block;
                    background-color: #fff;
                    width: 63%;
                    height: rem(1);

                    @include media($pb) {
                        width: 87%;
                    }
                }
            }

            .section__Title--inner {
                padding: rem(43) 0 0;

                @include media($pb) {
                    padding: rem(65) 0 rem(130);
                }
            }

            .section__Title--innerVer2 {
                padding: rem(107) 0 rem(10);

                @include media($pb) {
                    padding: rem(65) 0 rem(140);
                }
            }
        }


        .image01,
        .image02 {
            margin-right: calc(50% - 50vw);
            margin-left: calc(50% - 50vw);
            text-align: center;

            @include media($pb) {
                margin: 0;
            }
        }

        .image01 img,
        .image02 img {
            max-width: rem(375);

            @include media(xs){
                max-width: 100%;
            }

            @include media($pb) {
                position: absolute;
            }
        }

        .image01 img {

            @include media(lg) {
                max-width: rem(500);
                top: rem(-140);
                right: 0;
            }

            @include media(xxxl) {
                max-width: rem(837);
            }
        }

        .image02 img {

            @include media($pb) {
                max-width: rem(780);
                top: 0;
                right: 0;
            }
        }

        .section__Title--ul,
        .section__Title--ulVer2 {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: rem(27) rem(12);
            position: absolute;
            left: 0;
            padding: 0 rem(10);

            @include media($pb) {
                gap: rem(18);
                padding: 0;
            }

            figcaption {
                font-size: rem(14);
                margin-top: rem(6);
                font-weight: 500;
                letter-spacing: -0.08em;

                @include media($pb) {
                    font-size: rem(16);
                    margin-top: rem(14);
                    letter-spacing: normal;
                }
            }
        }

        .section__Title--ul {
            bottom: rem(-100);
            max-width: rem(514);

            @include media(xs){
                bottom: rem(-143);
            }
        }

        .section__Title--ulVer2 {
            bottom: rem(-192);
            max-width: rem(780);

            @include media(xs){
                bottom: rem(-210);
            }

            @include media(sm){
                bottom: rem(-320);
            }

            @include media($pb) {
                grid-template-columns: 1fr 1fr 1fr;
            }

            @include media(xxxl){
                bottom: rem(-210);
            }

            .section__Title--li {

                figcaption p {
                    font-weight: 400;
                }

                &:nth-of-type(1) figcaption p {
                    margin-top: rem(34);

                    @include media($pb) {
                        margin-top: 0;
                    }
                }

                &:nth-of-type(2) figcaption p,
                &:nth-of-type(3) figcaption p {
                    margin-top: rem(13);

                    @include media($pb) {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

.frontLoader__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.attachment{
    margin-top: rem(100);

    @include media(md){
        margin-top: rem(200);
    }
}


.line__up--lead,
.attachment--lead {
    background-color: $red02;
    margin-left: calc(50% - 50vw);
    margin-right: rem(10);
    padding: rem(18) 0 rem(15);
    width: 100%;
    background-image: url("../images/frontLoader/sp_bg@2x.png");
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;

    @include media($pb) {
        width: 97%;
        display: flex;
        align-items: center;
        padding: rem(50) 0;
        background-image: url("../images/frontLoader/bg@2x.png");
    }

    @include media(xxxl){
        width: 82%;
    }

    h2 {
        font-size: rem(40);
        font-style: italic;
        letter-spacing: 0.025em;

        @include media($pb) {
            font-size: rem(60);
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
            align-items: center;
        }

        .text {
            line-height: 1;
            padding-left: rem(45);
            font-family: $roboto;

            @include media($pb) {
                padding-left: 0;
            }
        }
    }

    p {
        font-size: rem(14);
        line-height: 2;
        padding: rem(20) 0 0 rem(62);

        @include media($pb) {
            font-size: rem(18);
            margin-left: rem(67);
            padding: 0;
        }
    }

    .line {
        display: block;
        width: rem(150);
        height: rem(1);
        background-color: #fff;
        margin-top: rem(5);

        @include media($pb) {
            width: rem(300);
            margin-top: 0;
            margin-right: rem(40);
        }
    }
}

// line__up--lead、attachment--lead


.attachment__title {

    h3.en {
        position: relative;
        font-family: $roboto;
        font-size: rem(28);
        font-weight: 100;
        font-style: italic;
        z-index: 1;

        @include media($pb) {
            font-size: rem(40);
        }

        .en_bg {
            position: absolute;
            top: rem(-22);
            left: rem(-12);
            z-index: -1;

            @include media($pb) {
                top: rem(-60);
                left: rem(-82);
            }
        }
    }

    h3.jp {
        font-size: rem(16);
        font-weight: 500;
        z-index: 10;

        @include media($pb) {
            font-size: rem(24);
        }
    }

    .lineup__title {
        padding-left: rem(10);

        @include media($pb) {
            display: flex;
            align-items: center;
            padding-left: rem(23);
        }
    }

    .attachment__title--ul._box {
        display: flex;
        gap: rem(12);
        width: rem(304);
        margin-top: rem(13);

        @include media($pb) {
            width: rem(435);
            margin-left: rem(40);
            margin-top: 0;
        }

        .attachment__title--li {
            font-size: rem(10);
            border: solid rem(1) #fff;
            text-align: center;
            display: inline-block;
            padding: rem(4) rem(14);

            @include media($pb) {
                font-size: rem(16);
            }
        }
    }


    .attachment__title--text {
        border-top: solid rem(1) #fff;
        margin-top: rem(18);
        padding: rem(24) rem(10) 0;

        @include media($pb) {
            margin-top: rem(22);
            padding: rem(24) 0 0 rem(23);
            width: 100%;
            max-width: rem(817);
        }

        p {
            font-size: rem(14);
            line-height: 2;
            text-align: justify;

            @include media($pb) {
                font-size: rem(18);
            }
        }
    }
}


//*------------------------------------*//
//    セクションごと
//*------------------------------------*//
.frontLoader {

    .fl__kv {
        margin-left: auto;
        padding: rem(42) 0 rem(536);
        width: rem(355);

        @include media(xs){
            padding: rem(40) 0 rem(100);
        }

        @include media(sm) {
            width: 50%;
            padding: rem(100) 0 rem(200);
        }

        @include media(lg) {
            padding: rem(200) 0 rem(536);
        }

        @include media(xl) {
            padding: rem(160) 0 rem(536);
        }

        @include media(xxl) {
            padding: rem(250) 0 rem(536);
        }

        h2 {
            font-size: rem(40);
            line-height: 1.35;

            @include media(xs){
                font-size: rem(24);
            }

            @include media(lg) {
                font-size: rem(50);
            }

            @include media(xxxl) {
                font-size: rem(60);
            }
        }

        h2 .text1 {
            font-size: rem(70);

            @include media(xs){
                font-size: rem(50);
            }

            @include media(lg) {
                font-size: rem(80);
            }

            @include media(xxxl) {
                font-size: rem(120);
            }
        }

        h2 .text2 {
            display: block;
            text-align: right;

            @include media(xs){
                text-align: left;
                display: inline;
            }
        }

        h2,
        h2 .text1,
        h2 .text2 {
            font-family: $zen_old;
            font-weight: 100;
        }

        p {
            font-weight: 100;
            font-size: rem(20);
            line-height: 1;
            margin-top: rem(26);

            @include media(xs){
                margin-top: rem(10);
            }

            @include media($pb) {
                font-size: rem(24);
                margin-top: 0;
            }
        }
    }

    // .fl__kv


    .sec1,
    .sec3,
    .sec4,
    .sec5,
    .sec6,
    .sec7,
    .sec8,
    .sec9,
    .sec10,
    .sec11{
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;

        @include media($pb){
            max-width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }

    .sec1 {
        padding-top: rem(20);

        @include media($pb) {
            padding-top: rem(40);
        }

        .lead {
            text-align: left;
            line-height: 2;
            letter-spacing: 0.3;
        }

        .lead h2 {
            font-size: rem(40);
            font-style: italic;
            display: flex;
            align-items: center;

            @include media($pb) {
                font-size: rem(60);
            }
        }

        .lead h2 .line {
            display: block;
            width: rem(88);
            background-color: #fff;
            height: rem(1);
            margin-left: rem(13);

            @include media($pb) {
                width: rem(300);
                margin-left: rem(26);
            }
        }

        .lead p {

            @include media($pb) {
                padding-left: rem(20);
            }
        }

        .sec1__lists {
            margin-top: rem(50);
            padding: 0;

            @include media(sm) {
                margin-top: rem(80);
            }
        }

        .sec1__lists--item {
            background-position: top left;
            background-repeat: no-repeat;
            background-size: cover;
            margin: 0 calc(50% - 50vw);
            padding: rem(24) calc(50vw - 50%) rem(40);

            @include media($pb) {
                padding: rem(50) calc(50vw - 50%) rem(40);
            }

            &:nth-of-type(1){
                background-image: url("../images/frontLoader/section1/1/sp_bg.jpg");

                @include media($pb) {
                    background-image: url("../images/frontLoader/section1/1/bg.jpg");
                }
            }

            &:nth-of-type(2){
                background-image: url("../images/frontLoader/section1/2/sp_bg.jpg");

                @include media($pb) {
                    background-image: url("../images/frontLoader/section1/2/bg.jpg");
                }
            }

            &:nth-of-type(3){
                background-image: url("../images/frontLoader/section1/3/sp_bg.jpg");

                @include media($pb) {
                    background-image: url("../images/frontLoader/section1/3/bg.jpg");
                }
            }

            &:nth-of-type(4){
                background-image: url("../images/frontLoader/section1/4/sp_bg.jpg");

                @include media($pb) {
                    background-image: url("../images/frontLoader/section1/4/bg.jpg");
                }
            }

            &:nth-of-type(5){
                background-image: url("../images/frontLoader/section1/5/sp_bg.jpg");

                @include media($pb) {
                    background-image: url("../images/frontLoader/section1/5/bg.jpg");
                }
            }

            &:not(:first-child) {
                padding-top: rem(24);

                @include media($pb) {
                    padding-top: rem(70);
                }
            }

            .inner {
                position: relative;
                padding-top: rem(56);

                @include media($pb) {
                    padding-top: rem(90);
                }

                h3 {
                    position: absolute;
                    top: 0;
                }

                .title1 img {
                    width: rem(109);
                    padding-left: rem(20);

                    @include media($pb) {
                        width: rem(175);
                        padding-left: rem(33);
                    }
                }

                .title2 img {
                    width: rem(121);
                    padding-left: rem(10);

                    @include media($pb) {
                        width: rem(193);
                    }
                }

                .title3 img {
                    width: rem(218);
                    padding-left: rem(10);

                    @include media($pb) {
                        width: rem(354);
                    }
                }

                .title4 img {
                    width: rem(121);
                    padding-left: rem(10);

                    @include media($pb) {
                        width: rem(194);
                    }
                }

                .title5 img {
                    width: rem(198);
                    padding-left: rem(10);

                    @include media($pb) {
                        width: rem(321);
                    }
                }
            }
        }

        .contentsBox {
            display: grid;
            grid-template-columns: 1fr;

            @include media($pb) {
                grid-template-columns: 1fr 1fr;
            }
        }

        .videoBox {

            @include media($pb) {
                width: 100%;
                max-width: rem(562);
                padding-right: rem(58);
            }

            iframe {
                width: 100%;
                height: 100%;
            }
        }

        .itemBox {
            margin-top: rem(27);

            @include media($pb) {
                margin-top: 0;
            }

            h4 {
                font-size: rem(28);
                font-weight: 200;
                display: flex;
                align-items: center;
                font-style: italic;
                letter-spacing: -0.05em;
                font-family: $roboto;
                color: #272727;

                @include media($pb) {
                    font-size: rem(40);
                    letter-spacing: normal;
                }
            }

            h4 span {
                display: block;
                background-color: #272727;
                width: rem(62);
                height: rem(1);
                margin-left: rem(15);

                @include media($pb) {
                    width: rem(124);
                }
            }
        }

        .itemBox__link {
            display: flex;
            align-items: center;
        }

        .itemBox__ul {
            margin-top: rem(10);
            padding: rem(10);
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: rem(20) rem(11);
            background-color: #fff;

            @include media($pb) {
                margin-top: rem(28);
            }

            p {
                display: flex;
                align-items: center;
                color: #272727;
            }

            p svg {
                margin-left: rem(6);
            }
        }
    }

    // .sec1

    .line__up {
        padding-top: rem(102);

        @include media($pb) {
            padding-top: rem(210);
        }
    }

    // line__up

    .sec2 {
        margin-top: rem(80);

        @include media($pb) {
            margin-top: rem(218);
        }

        .feature {
            margin-top: rem(183);

            @include media($pb) {
                margin-top: rem(270);
            }

            .feature__ul {
                display: grid;
                grid-template-columns: 1fr;
                gap: rem(37) 0;

                @include media($pb) {
                    grid-template-columns: 1fr 1fr;
                    gap: rem(50) 0;
                    padding: 0 rem(20);
                }
            }

            .feature__li {

                &:nth-of-type(1) {
                    order: 1;
                }

                &:nth-of-type(2) {
                    order: 4;

                    @include media($pb) {
                        order: 2;
                    }
                }

                &:nth-of-type(3) {
                    order: 2;

                    @include media($pb) {
                        order: 3;
                    }
                }

                &:nth-of-type(4) {
                    order: 5;

                    @include media($pb) {
                        order: 4;
                    }
                }

                &:nth-of-type(5) {
                    order: 3;

                    @include media($pb) {
                        order: 5;
                    }
                }

                &:nth-of-type(6) {
                    order: 6;
                }

                ._link {
                    position: relative;
                    display: block;
                    color: $red;
                    background-color: #000;
                    border: solid rem(1) $red;
                    text-align: center;
                    padding: rem(18) rem(10);
                    box-shadow: 0 0 rem(10) $red02;
                    margin-top: rem(30);

                    @include media(xs) {
                        max-width: rem(278);
                    }
                }

                ._link svg {
                    position: absolute;
                    width: rem(12);
                    top: 50%;
                    transform: translateY(-50%);
                    right: rem(20);
                }
            }

            .feature__li--inner {
                display: flex;
                justify-content: space-between;

                @include media(xs){
                    justify-content: flex-start;
                }

                ._item02{

                    @include media(xs){
                        margin-left: rem(20);
                    }
                }

                ._item02._img1 {
                    width: rem(160);
                    margin-top: rem(40);

                    @include media($pb) {
                        width: rem(222);
                        margin-top: 0;
                    }
                }

                ._item02._img2 {
                    width: rem(160);

                    @include media($pb) {
                        width: rem(255);
                    }
                }

                ._item02._img3 {
                    width: rem(161);

                    @include media($pb) {
                        width: rem(239);
                    }
                }

                ._item02._img4 {
                    width: rem(162);
                    margin-top: rem(65);

                    @include media($pb) {
                        width: rem(223);
                        margin-top: rem(26);
                    }
                }
            }


            h4 {
                display: inline-block;
                font-size: rem(18);
                background-color: $red02;
                text-align: center;
                padding: rem(10) rem(13);
                font-weight: 400;
                letter-spacing: -0.025em;

                @include media($pb) {
                    font-size: rem(24);
                    padding: rem(14) rem(60);
                    letter-spacing: normal;
                }
            }

            p {
                font-size: rem(14);
                line-height: 2;
                letter-spacing: 0.025em;

                @include media($pb) {
                    font-size: rem(18);
                }
            }

            ._text {
                padding: rem(10) 0 0 rem(12);

                @include media($pb) {
                    padding: rem(12) 0 0 rem(32);
                }

                p {
                    line-height: 1.7;

                    @include media($pb) {
                        line-height: 2;
                    }
                }
            }

            .notes {
                font-size: rem(12);

                @include media($pb) {
                    font-size: rem(14);
                }
            }
        }
    }

    // sec2

    .sec3 {
        position: relative;
        margin-top: rem(114);
        padding-bottom: rem(170);

        @include media($pb) {
            margin-top: rem(225);
            padding-bottom: rem(267);
        }

        .section__Title--line {
            width: 87%;
        }

        .numLists {

            .numLists__wrap {
                position: relative;
                z-index: 1;
            }

            .numLists__inner {
                margin-top: rem(12);
                width: 100%;
            }

            .numLists__lead {
                font-size: rem(14);
                font-weight: 500;
                text-align: justify;
                line-height: 2;
                letter-spacing: 0.05em;
                padding-left: rem(10);

                @include media($pb) {
                    font-size: rem(16);
                    letter-spacing: normal;
                    padding-left: 0;
                }
            }

            .numLists__ul {
                padding: 0 rem(10);

                @include media($pb) {
                    padding: 0;
                }

                .numLists__box {
                    border: solid rem(1) #fff;
                    padding: rem(14) rem(20);
                    line-height: 1.75;

                    @include media($pb){
                        padding: rem(14) rem(20);
                    }
                }

                .numLists__box h4 {
                    font-size: rem(16);
                    font-weight: 500;

                    @include media($pb) {
                        font-size: rem(20);
                    }
                }

                .numLists__box p {
                    font-weight: 400;
                    // margin-top: rem(10);
                    font-size: rem(14);

                    @include media($pb) {
                        font-size: rem(16);
                    }
                }

                .numLists__box .numLists__box--notes {
                    font-size: rem(12);
                    display: block;
                }
            }

            .container {

                @include media(xxxl) {
                    padding-left: rem(20);
                }
            }

        }

        // numLists

        ._one {
            margin-top: rem(233);
            position: relative;
            z-index: $bg;

            @include media(sm){
                margin-top: rem(360);
            }

            @include media($pb) {
                margin-top: rem(333);
                padding-top: rem(200);
            }

            .numLists__wrap {
                z-index: 1;

                @include media($pb) {
                    padding-left: rem(16);
                }
            }

            .numLists__ul {
                display: grid;
                grid-template-columns: 1fr;
                gap: rem(20);
                margin-top: rem(16);

                @include media($pb) {
                    grid-template-columns: 1fr 1fr;
                    margin-top: rem(22);
                }
            }

            .numLists__inner {

                @include media($pb) {
                    max-width: rem(694);
                    padding-left: rem(20);
                }
            }

            .numLists__one--img {
                max-width: rem(1561);
                margin-right: calc(50% - 50vw);

                @include media($pb) {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }

        // __one

        ._two {
            position: relative;
            margin-top: rem(-210);
            padding-top: rem(449);

            @include media(xs) {
                margin-top: rem(-370);
                padding-top: rem(737);
            }

            @include media(lg) {
                margin-top: rem(-260);
                padding-top: rem(450);
            }

            @include media(xxl) {
                margin-top: rem(-260);
                padding-top: rem(500);
            }

            @include media(xxxl) {
                margin-top: rem(-390);
                padding-top: rem(550);
            }

            .numLists__inner {
                width: 100%;
                max-width: rem(682);
                margin: 0 auto;
            }

            .numLists__lead {
                margin-top: rem(20);

                @include media(sm) {
                    padding-left: rem(30);
                }

                @include media(lg) {
                    padding-left: rem(180);
                }

                @include media(xxxl) {
                    padding-left: 0;
                }
            }

            .numLists__two--img {
                position: absolute;
                top: 0;
                left: 0;
                max-width: rem(1650);
                margin-left: calc(50% - 50vw);

                @include media(sm){
                    margin-left: 0;
                }

                @include media(lg){
                    margin-left: calc(50% - 50vw);
                }
            }
        }

        // _two

        ._three {
            margin-top: rem(60);

            @include media(xs){
                margin-top: rem(220);
            }

            @include media($pb) {
                margin-top: rem(5);
            }

            .wrapperRight {
                padding-top: rem(74);

                @include media(xs){
                    padding-top: rem(126);
                }

                @include media(md){
                    padding-top: rem(160);
                }

                @include media(xl){
                    padding-top: rem(183);
                }

                @include media(xxl) {
                    padding-top: rem(188);
                }
            }

            .numLists__three--img {
                position: absolute;
                top: 0;
                left: 0;
                max-width: rem(1113);
                margin-right: calc(50% - 50vw);

                @include media($pb) {
                    margin-right: 0;
                }
            }

            .numLists__lead {
                width: rem(208);

                @include media($pb) {
                    width: auto;
                }
            }
        }

        // _three

        ._four {
            margin-top: rem(25);

            @include media(xs){
                margin-top: rem(160);
            }

            @include media($pb) {
                margin-top: rem(52);
            }

            .wrapperRight {
                padding-top: rem(197);

                @include media(xs){
                    padding-top: rem(320);
                }

                @include media($pb) {
                    padding-top: rem(100);
                }
            }

            .numLists__four--img {
                position: absolute;
                top: 0;
                left: 0;
                max-width: rem(1141);
            }

            .numLists__ul {
                display: grid;
                grid-template-columns: 1fr;
                gap: rem(20);
                margin-top: rem(20);
                width: 100%;
                max-width: rem(655);

                @include media($pb) {
                    margin-top: rem(22);
                }

                .numLists__box--notes {

                    @include media($pb) {
                        margin-top: rem(22);
                    }
                }
            }
        }

        // _four

        ._five {
            position: relative;
            margin: rem(55) auto 0;
            padding-top: rem(73);
            width: 100%;
            max-width: rem(1530);
            z-index: 1;

            @include media(xs){
                margin: rem(100) auto 0;
                padding-top: rem(124);
            }

            @include media(xl) {
                margin: rem(110) auto 0;
                padding-top: rem(310);
            }

            @include media(xxll) {
                margin: rem(-160) auto 0;
                padding-top: rem(310);
            }

            @include media(xxxl) {
                margin: rem(-130) auto 0;
            }

            .numLists__inner {
                width: 100%;
                max-width: rem(682);
                margin: 0 auto;
            }

            .numLists__lead {
                margin-top: rem(20);
                width: rem(176);

                @include media($pb) {
                    width: auto;
                }
            }

            .numLists__five--img {
                position: absolute;
                top: 0;
                left: 0;
                max-width: rem(655);
                margin-right: calc(50% - 50vw);

                @include media($pb) {
                    margin-right: 0;
                }
            }
        }

        // _five

        ._six {
            position: relative;
            margin: rem(30) auto 0;
            padding-top: rem(207);
            width: 100%;
            max-width: rem(1530);
            z-index: 1;

            @include media(xs){
                margin: rem(170) auto 0;
                padding-top: rem(340);
            }

            @include media($pb) {
                margin: rem(-40) auto 0;
                padding-top: rem(180);
            }

            .numLists__inner {
                width: 100%;
                max-width: rem(682);
                margin: 0 auto;
            }

            .numLists__lead {
                margin-top: rem(20);
            }

            .numLists__six--img {
                position: absolute;
                top: 0;
                left: 0;
                max-width: rem(630);
                margin-right: calc(50% - 50vw);

                @include media($pb) {
                    margin-right: 0;
                }
            }

            .numLists__ul {
                margin-top: rem(22);
                width: 100%;
                max-width: rem(450);
            }

            .numLists__box p {
                display: flex;

                span {
                    display: inline-block;
                    padding-left: rem(6);
                }
            }
        }

        // _six

        ._seven {
            position: relative;
            margin: rem(43) auto 0;
            padding-top: rem(73);
            width: 100%;
            max-width: rem(1530);

            @include media(xs){
                margin: rem(67) auto 0;
                padding-top: rem(120);
            }

            @include media($pb) {
                padding-top: rem(90);
            }

            .numLists__inner {
                width: 100%;
                max-width: rem(682);
                margin: 0 auto;
            }

            .numLists__lead {
                margin-top: rem(20);
                margin-left: auto;
                padding-left: 0;
                padding-right: rem(10);
                width: rem(256);

                @include media($pb) {
                    width: auto;
                    margin-left: 0;
                    padding-right: 0;
                }
            }

            .numLists__seven--img {
                position: absolute;
                top: 0;
                left: 0;
                max-width: rem(690);
            }
        }

        // _seven

        .sec3__video {
            margin-top: rem(52);

            @include media(xs){
                margin-top: rem(140);
            }

            @include media($pb) {
                margin-top: rem(56);
            }

            h3 {
                position: relative;
                font-weight: 100;
                padding-top: rem(47);

                @include media($pb) {
                    padding-top: 0;
                }

                .en {
                    position: absolute;
                    top: 0;
                    left: 0;
                    font-size: rem(40);
                    font-style: italic;
                    font-family: $roboto;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-right: calc(50% - 50vw);

                    @include media($pb) {
                        position: static;
                        font-size: rem(60);
                        margin-right: 0;
                    }

                    .line {
                        display: block;
                        background-color: #fff;
                        width: 50%;
                        height: rem(1);
                        width: 100%;
                        max-width: rem(239);
                        margin: 0 0 0 rem(15);

                        @include media($pb) {
                            max-width: rem(300);
                            margin: rem(12) 0 0 rem(28);
                        }
                    }
                }

                .jp {
                    font-size: rem(18);

                    @include media($pb) {
                        font-size: rem(24);
                    }
                }
            }

            .sec3__video--ul {
                margin-top: rem(25);
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: rem(20) rem(14);
                width: 100%;
                max-width: rem(1120);
                padding: 0 rem(10) 0;

                @include media($pb) {
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    gap: rem(27) rem(48);
                    margin-top: rem(40);
                    padding: 0 0 0 rem(15);
                }

                .sec3__video--li {
                    z-index: 1;
                }

                h4 {
                    font-family: $roboto;
                    color: $red;
                    font-weight: 100;

                    .en{
                        font-size: rem(28);
                        font-style: italic;
                        font-family: $roboto;

                        @include media($pb) {
                            font-size: rem(40);
                        }
                    }

                    .ja{
                        display: block;
                        font-size: rem(14);
                        font-weight: 500;
                    }
                }

                .youtube_link {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #000;
                    border: solid rem(1) $red;
                    box-shadow: 0 0 rem(10) $red02;
                    margin-top: rem(8);
                    min-height: rem(50);

                    @include media(sm){
                        min-height: rem(64);
                    }
                }

                .youtube_link_wrap{
                    display: block;
                    width: 100%;
                    padding: rem(6) rem(15);

                    @include media($pb) {
                        padding: rem(9) rem(18);
                    }
                }

                .youtube_link_wrap .look_movie,
                .youtube_link_wrap .click_here,
                .youtube_link_wrap .coming_soon{
                    display: block;
                    font-weight: 500;
                    letter-spacing: 0.03em;
                    text-align: center;
                }

                .youtube_link_wrap .look_movie{
                    font-size: rem(14);
                    color: $white;

                    @include media(sm){
                        font-size: rem(18);
                    }
                }

                .youtube_link_wrap .click_here{
                    font-family: $roboto;
                    font-size: rem(12);
                    text-transform: uppercase;
                    color: $red;
                    margin-top: rem(4);
                }

                .youtube_link_wrap .coming_soon{
                    font-family: $roboto;
                    font-size: rem(14);
                    text-transform: uppercase;
                    color: $red;
                }

                .sec3__video--box  {

                    svg {

                        position: absolute;
                        width: rem(12);
                        top: 50%;
                        transform: translateY(-50%);
                        right: rem(7);

                        @include media($pb){
                            right: rem(20);
                        }
                    }

                    img {
                        width: rem(90);
                        margin: 0 auto;

                        @include media($pb) {
                            width: rem(120);
                        }
                    }
                }
            }
        }

        ._bg-img {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            max-width: rem(291);
            margin-right: calc(50% - 50vw);

            @include media($pb) {
                max-width: rem(1013);
            }
        }

    }

    // sec3

    .sec4 {

        .sec4__lists--ul {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: rem(167) rem(167) rem(214);
            gap: rem(20) rem(14);
            margin-top: rem(32);

            @include media($pb) {
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: rem(278) rem(278);
                gap: rem(30);
                margin-top: rem(77);
                padding: 0 rem(20);
            }
        }


        .sec4__lists--li {
            border: solid rem(1) $red;
            padding-top: rem(12);
            background-color: #D9D9D9;
            color: #fff;

            &:nth-of-type(5),
            &:nth-of-type(6) {
                padding-top: rem(20);

                @include media($pb) {
                    padding-top: rem(12);
                }
            }

            ._name {
                font-size: rem(10);
                padding: rem(3) 0 0 rem(10);
                color: #000000;

                @include media($pb) {
                    font-size: rem(14);
                    padding: rem(6) 0 0 rem(18);
                }
            }

            .sec4__lists--img {
                width: 100%;
                margin: 0 auto;
            }

            ._item01 {
                max-width: rem(100);
                margin-top: rem(11);

                @include media($pb) {
                    max-width: rem(223);
                }
            }

            ._item02 {
                max-width: rem(85);
                margin-left: rem(27);

                @include media($pb) {
                    max-width: rem(160);
                    margin-left: auto;
                }
            }

            ._item03 {
                max-width: rem(155);
                margin-top: rem(20);

                @include media($pb) {
                    max-width: rem(300);
                    margin-top: rem(32);
                }
            }

            ._item04 {
                max-width: rem(90);
                margin-top: rem(6);

                @include media($pb) {
                    max-width: rem(161);
                    margin-top: rem(12);
                }
            }

            ._item05 {
                max-width: rem(90);
                margin-top: rem(10);

                @include media($pb) {
                    max-width: rem(170);
                    margin-top: 0;
                }
            }

            ._item06 {
                max-width: rem(90);
                margin-top: rem(6);

                @include media($pb) {
                    max-width: rem(150);
                    margin-top: rem(22);
                }
            }
        }

        .sec4__lists--li:last-child .sec4__lists--link {

            @include media($pb) {
                margin-right: rem(43);
            }
        }

        .sec4__lists--link {
            display: block;
            margin-right: rem(14);
            font-size: rem(14);
            color: #fff;
            line-height: 1.2;

            @include media($pb) {
                font-size: rem(24);
                margin-right: rem(100);
            }
        }

        .sec4__lists--link span {
            display: flex;
            align-items: center;
            background-color: $red02;
            padding: rem(7) 0 rem(7) rem(10);

            @include media($pb) {
                padding: rem(3) 0 rem(3) rem(18);
            }

            svg {
                margin: 0 0 0 rem(12);
                width: rem(8);

                @include media($pb) {
                    margin: rem(8) 0 0 rem(12);
                    width: rem(14);
                }
            }
        }
    }

    // sec4


    .sec5 {
        padding-top: rem(80);

        @include media($pb) {
            padding-top: rem(160);
        }

        .containerVer3 {
            position: relative;
            padding-top: rem(70);

            @include media($pb) {
                padding-top: rem(110);
            }
        }

        .title_img {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            max-width: rem(188);
            margin-right: calc(50% - 50vw);

            @include media(xs){
                max-width: rem(260);
            }

            @include media(lg){
                max-width: rem(400);
            }

            @include media(xxl){
                max-width: rem(500);
            }

            @include media(xxxl) {
                max-width: rem(690);
            }
        }

        h3.en {

            .en_bg {
                top: rem(-22);
                left: rem(-12);

                @include media($pb) {
                    top: rem(-60);
                    left: rem(-82);
                }
            }
        }

        .sec5__lineup {
            margin-top: rem(12);

            @include media($pb) {
                margin-top: rem(90);
            }
        }

        .sec5__lineup--ul {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: rem(20) rem(15);

            @include media($pb) {
                grid-template-columns: 1fr 1fr 1fr;
                gap: rem(40) rem(30);
            }
        }

        .sec5__lineup p {
            font-size: rem(12);
            line-height: 1.5;
            padding: 0 rem(10);
            letter-spacing: 0.025em;

            @include media($pb) {
                font-size: rem(14);
                margin-top: rem(10);
                padding: 0 rem(16);
                letter-spacing: normal;
            }
        }

    }

    //sec5

    .sec6 {
        padding-top: rem(38);

        @include media(xs) {
            padding-top: rem(110);
        }

        .containerVer3 {
            position: relative;
            padding-top: rem(74);

            @include media(xs){
                padding-top: rem(170);
            }

            @include media($pb) {
                padding-top: rem(112);
            }
        }

        .attachment__title--text {

            @include media($pb) {
                width: auto;
            }
        }

        .title_img {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            max-width: rem(170);
            margin-right: calc(50% - 50vw);

            @include media(xs){
                max-width: rem(260);
            }

            @include media(lg){
                max-width: rem(400);
                left: 0;
                margin-left: calc(50% - 50vw);
            }

            @include media(xxxl) {
                max-width: rem(662);
            }
        }

        h3.en {

            .en_bg {
                top: rem(-22);
                left: rem(-12);

                @include media($pb) {
                    top: rem(-60);
                    left: rem(-82);
                }
            }
        }

        .sec6__inner {

            @include media($pb) {
                width: 100;
                max-width: rem(789);
                margin-left: auto;
            }
        }

        .sec6__lineup {
            margin-top: rem(12);

            @include media($pb) {
                margin-top: rem(40);
            }
        }

        .sec6__lineup--ul {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: rem(20) rem(15);

            @include media($pb) {
                gap: rem(40) rem(30);
            }
        }


        .sec6__lineup p {
            font-size: rem(12);
            line-height: 1.5;
            padding: 0 rem(10);
            letter-spacing: 0.025em;

            @include media($pb) {
                font-size: rem(14);
                margin-top: rem(10);
                padding: 0 rem(16);
                letter-spacing: normal;
            }
        }
    }

    // sec6

    .sec7 {
        padding-top: rem(80);

        @include media($pb) {
            padding-top: rem(160);
        }

        .containerVer3 {
            position: relative;
            padding-top: rem(75);

            @include media(xs){
                padding-top: rem(120);
            }

            @include media($pb) {
                padding-top: rem(67);
            }
        }

        .title_video-box{
            position: relative;
        }

        .title_text {
            position: absolute;
            top: rem(-8);
            left: 0;
            font-family: $roboto;
            font-weight: 100;
            font-style: italic;
            font-size: rem(17);
            text-transform: uppercase;
            z-index: 1;

            @include media($pb) {
                font-size: rem(60);
                top: rem(-38);
                left: auto;
            }
        }

        .title_img {
            position: absolute;
            top: 0;
            right: 0;
            margin-right: calc(50% - 50vw);

            ._thumbnail{
                width: rem(187);
                height: rem(109);

                @include media(xs){
                    width: rem(300);
                    height: rem(175);
                }

                @include media(lg){
                    width: rem(400);
                    height: rem(225);
                }

                @include media(xxl){
                    width: rem(500);
                    height: rem(293);
                }

                @include media(xxxl) {
                    width: rem(662);
                    height: rem(388);
                }
            }
        }

        h3.en {

            .en_bg {
                top: rem(-22);
                left: rem(-12);

                @include media($pb) {
                    top: rem(-60);
                    left: rem(-82);
                }
            }
        }

        .sec7__lineup {
            margin-top: rem(54);

            @include media($pb) {
                margin-top: rem(90);
            }
        }

        .sec7__lineup--ul {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: rem(20) rem(15);
            margin-top: rem(46);

            @include media($pb) {
                gap: rem(40) rem(30);
                margin-top: rem(123);
            }
        }

        .sec7__lineup--item05 {
            margin-top: rem(44);
            overflow-x: scroll;

            .sec7__lineup--item05--scroll{
                width: rem(832);
                height: 100%;
                overflow-x: scroll;
                margin: 0 auto;

                @include media(md) {
                    width: 100%;
                }
            }
        }

        .sec7__lineup--item07{
            max-width: rem(500);
            margin: rem(40) auto 0;
        }

        .sec7__lineup p {
            font-size: rem(12);
            line-height: 1.5;
            padding: 0 rem(10);
            letter-spacing: 0.025em;

            @include media($pb) {
                font-size: rem(14);
                margin-top: rem(18);
                padding: 0 rem(18);
                letter-spacing: normal;
            }
        }

        .sec7__lineup--kit {
            display: flex;
            justify-content: space-between;
            margin-top: rem(60);

            @include media(xs){
                justify-content: flex-start;
                margin-top: rem(80);
            }
        }

        .sec7__lineup--kit--text {

            h4 {
                font-size: rem(18);
                background-color: $red02;
                padding: rem(10) 0;
                text-align: center;
                max-width: rem(196);
                font-weight: 100;

                @include media($pb) {
                    font-size: rem(24);
                    width: 100%;
                    max-width: rem(347);
                    padding: rem(10);
                }
            }

            p {
                font-size: rem(14);
                line-height: 2;
                margin-top: rem(20);

                @include media($pb) {
                    font-size: rem(18);
                    margin-top: rem(16);
                    padding-left: rem(26);
                }
            }
        }

        .sec7__lineup--kit--img {
            width: rem(146);
            margin-top: rem(77);

            @include media(xs){
                margin-left: rem(10);
            }

            @include media($pb) {
                width: rem(190);
                margin-top: 0;
                margin-left: rem(38);
            }
        }

    }

    //sec7

    .sec8 {
        padding-top: rem(76);

        @include media($pb) {
            padding-top: rem(110);
        }

        .containerVer3 {
            position: relative;
            padding-top: rem(74);

            @include media(xs){
                padding-top: rem(120);
            }

            @include media($pb) {
                padding-top: rem(112);
            }
        }

        .attachment__title--text {

            @include media($pb) {
                width: auto;
            }
        }

        .title_img {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            max-width: rem(180);
            margin-right: calc(50% - 50vw);

            @include media(xs){
                max-width: rem(260);
            }

            @include media(lg){
                max-width: rem(400);
                margin-left: calc(50% - 50vw);
                left: 0;
            }

            @include media(xxxl) {
                max-width: rem(662);
            }
        }

        h3.en {

            .en_bg {
                top: rem(-22);
                left: rem(-12);

                @include media($pb) {
                    top: rem(-60);
                    left: rem(-82);
                }
            }
        }

        .sec8__inner {

            @include media($pb) {
                width: 100;
                max-width: rem(789);
                margin-left: auto;
            }
        }

        .sec8__lineup {
            margin-top: rem(12);

            @include media($pb) {
                margin-top: rem(40);
            }
        }

        .sec8__lineup--ul {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: rem(20) rem(15);

            @include media($pb) {
                gap: rem(40) rem(30);
            }
        }

        .sec8__lineup--ulVer2 {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: rem(20) rem(15);

            @include media($pb) {
                grid-template-columns: 1fr 1fr 1fr;
                gap: rem(40) rem(30);
                padding-top: rem(30);
            }
        }


        .sec8__lineup p {
            font-size: rem(12);
            line-height: 1.5;
            padding: 0 rem(10);
            letter-spacing: 0.025em;

            @include media($pb) {
                font-size: rem(14);
                margin-top: rem(10);
                padding: 0 rem(16);
                letter-spacing: normal;
            }
        }
    }

    // sec8

    .sec9 {
        padding-top: rem(28);

        @include media(xs) {
            padding-top: rem(120);
        }

        .containerVer3 {
            position: relative;
            padding-top: rem(100);

            @include media(xs) {
                padding-top: rem(160);
            }

            @include media($pb) {
                padding-top: rem(110);
            }
        }

        .title_img {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            max-width: rem(188);
            margin-right: calc(50% - 50vw);

            @include media(xs) {
                max-width: rem(260);
            }

            @include media(lg){
                max-width: rem(400);
            }

            @include media(xxxl) {
                max-width: rem(673);
            }
        }

        .sec9__lineup {
            margin-top: rem(12);
            width: 100%;

            @include media($pb) {
                margin-top: rem(90);
                max-width: rem(790);
                margin-top: rem(37);
            }
        }

        .sec9__lineup--ul {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: rem(20) rem(15);

            @include media($pb) {
                gap: rem(40) rem(30);
            }
        }

        .sec9__lineup p {
            font-size: rem(12);
            line-height: 1.5;
            padding: 0 rem(10);
            letter-spacing: 0.025em;

            @include media($pb) {
                font-size: rem(14);
                margin-top: rem(10);
                padding: 0 rem(16);
                letter-spacing: normal;
            }
        }

    }

    //sec9

    .sec10 {
        padding-top: rem(60);

        @include media(xs) {
            padding-top: rem(150);
        }

        .containerVer3 {
            position: relative;
            padding-top: rem(74);

            @include media(xs) {
                padding-top: rem(120);
            }

            @include media($pb) {
                padding-top: rem(112);
            }
        }

        .attachment__title--text {

            @include media($pb) {
                width: auto;
            }
        }

        .title_img {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            max-width: rem(188);
            margin-right: calc(50% - 50vw);

            @include media(xs) {
                max-width: rem(260);
            }

            @include media(lg){
                max-width: rem(400);
                margin-left: calc(50% - 50vw);
                left: 0;
            }

            @include media(xxxl) {
                max-width: rem(662);
            }
        }

        h3.en {

            .en_bg {
                top: rem(-22);
                left: rem(-12);

                @include media($pb) {
                    top: rem(-60);
                    left: rem(-82);
                }
            }
        }

        .sec10__inner {

            @include media($pb) {
                width: 100;
                max-width: rem(789);
                margin-left: auto;
            }
        }

        .sec10__lineup {
            margin-top: rem(12);

            @include media($pb) {
                margin-top: rem(40);
            }
        }

        .sec10__lineup--ul {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: rem(20) rem(15);

            @include media($pb) {
                gap: rem(40) rem(30);
            }
        }

        .sec10__lineup p {
            font-size: rem(12);
            line-height: 1.5;
            padding: 0 rem(10);
            letter-spacing: 0.025em;

            @include media($pb) {
                font-size: rem(14);
                margin-top: rem(10);
                padding: 0 rem(16);
                letter-spacing: normal;
            }
        }
    }

    // sec10

    .sec11 {
        padding-top: rem(120);

        @include media($pb){
            padding-top: rem(166);
        }

        .lead {
            text-align: left;
            line-height: 2;
            letter-spacing: 0.3;
        }

        .lead h2 {
            font-size: rem(40);
            font-style: italic;
            display: flex;
            align-items: center;
            line-height: 1;

            @include media($pb) {
                font-size: rem(60);
            }
        }

        .lead h2 .line {
            display: block;
            width: rem(160);
            background-color: #fff;
            height: rem(1);
            margin-left: rem(13);

            @include media($pb) {
                width: rem(300);
                margin-left: rem(26);
            }
        }

        .lead .jp {
            font-size: rem(18);

            @include media($pb) {
                font-size: rem(24);
            }
        }

        .sec11__ul{
            margin-top: rem(25);

            @include media($pb){
                margin-top: rem(30);
                padding: 0 rem(20);
            }
        }

        .sec11__li:not(:first-child){
            margin-top: rem(22);

            @include media($pb){
                margin-top: rem(40);
            }
        }

        .sec11__dt{
            font-size: rem(18);
            background-color: $red02;
            padding: rem(10) rem(20);

            @include media($pb){
                font-size: rem(24);
                padding: rem(13) rem(40);
            }
        }

        .sec11__dd{
            font-size: rem(14);
            line-height: 2;
            border-bottom: solid rem(1) $red02;
            border-left: solid rem(1) $red02;
            border-right: solid rem(1) $red02;
            padding: rem(13) rem(20);

            @include media($pb){
                font-size: rem(20);
                padding: rem(13) rem(40);
            }
        }
    }

    // sec11

}

// frontLoader





.mx--full {
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
}

.ml--full {
    margin-left: calc(50% - 50vw);
}

.mr--full {
    margin-right: calc(50% - 50vw);
}

.px--full {
    padding-right: calc(50vw - 50%);
    padding-left: calc(50vw - 50%);
}

.pl--full {
    padding-left: calc(50vw - 50%);
}

.pr--full {
    padding-right: calc(50vw - 50%);
}
