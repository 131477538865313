@charset "UTF-8";
.hover {
  transition: opacity 0.3s ease-out;
  transition: all 0.5s ease-out;
}
.hover:focus {
  opacity: 0.8;
}
@media (hover: hover) {
  .hover:hover {
    opacity: 0.8;
  }
}

.list-style_none {
  list-style: none;
}

._flex {
  display: flex;
}

._block {
  display: block;
}

.pointer_events_none {
  pointer-events: none;
}

._no-image {
  position: relative;
  padding: 0 !important;
}
._no-image img {
  width: 100% !important;
  height: 100% !important;
}
._no-image ._textBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}
._no-image ._textBox p {
  font-size: 1.125rem;
  margin: 0;
}
@media screen and (min-width: 769px) {
  ._no-image ._textBox p {
    font-size: 1.625rem;
  }
}
._no-image ._textBox p:first-child {
  font-size: 2.25rem;
}
@media screen and (min-width: 769px) {
  ._no-image ._textBox p:first-child {
    font-size: 2.5rem;
  }
}
._no-image ._textBox p + p {
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  ._no-image ._textBox p + p {
    margin-top: 1.875rem;
  }
}

.ContactLinkArea,
.lead-obi {
  background: #186747;
}

.ContactLinkArea .AreaLink a {
  color: #186747;
}

.ContactLinkArea .AreaLink a svg {
  position: absolute;
  width: 35px;
  left: 19px;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (min-width: 769px) {
  .ContactLinkArea .AreaLink a svg {
    width: 42px;
    left: 30px;
  }
}

.page-numbers {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
}
@media screen and (min-width: 769px) {
  .page-numbers {
    font-size: 1.25rem;
  }
}

.page-numbers li {
  margin: 0 0.25rem;
}
@media screen and (min-width: 769px) {
  .page-numbers li {
    margin: 0 0.3125rem;
  }
}

.page-numbers a,
.page-numbers span {
  display: block;
  padding: 0.3125rem 0.5rem;
  text-decoration: none;
  color: #000;
  background-color: #fff;
  transition: background-color 0.3s ease, color 0.3s ease;
}
@media screen and (min-width: 769px) {
  .page-numbers a,
  .page-numbers span {
    padding: 0.625rem 0.9375rem;
  }
}

.page-numbers a:hover {
  opacity: 0.7;
}

.page-numbers .current {
  background-color: #C7000A;
  color: #fff;
  border-color: #C7000A;
}

.page-numbers .dots {
  padding: 0.625rem 0.9375rem;
  color: #333;
  background: none;
  border: none;
}

/* Add styles for first and last buttons */
.page-numbers .first,
.page-numbers .last {
  font-weight: bold;
}

/* モーダルを複数設置するためのwrapperクラス */
.modalTarget {
  position: relative;
}

/* モーダルを開くボタン */
.modal-open {
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
}

/* モーダルと背景の指定 */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 99999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  overflow: auto;
  transition: 0.3s;
  box-sizing: border-box;
  padding: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* クラスが追加された時の指定 */
.modal.is-active {
  opacity: 1;
  visibility: visible;
}

/* モーダル内側の指定 */
.modal-container {
  position: relative;
  margin: 0 auto;
}

/* モーダルを閉じるボタンの指定 */
.modal-close {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.modal-close.modal-close-frontLoader {
  top: -2.5rem;
  right: 0;
  width: 20px;
}
@media screen and (min-width: 769px) {
  .modal-close.modal-close-frontLoader {
    top: -3.125rem;
    width: 1.875rem;
  }
}

/* モーダルのコンテンツ部分の指定 */
.modal-content {
  flex: 1;
}

/* モーダル内の動画 */
.iframe-wrapper {
  width: 80vw;
  height: 45vw;
}
@media screen and (min-width: 769px) {
  .iframe-wrapper {
    width: 70vw;
    height: 40vw;
  }
}

.iframe-wrapper iframe {
  width: 100%;
  height: 100%;
}

.un_catalog_title,
.un_catalog_lead {
  text-align: center;
  padding: 0 1.25rem;
}

.bl_catalog {
  margin-top: 3.125rem;
  padding: 0 1.25rem;
}
@media screen and (min-width: 769px) {
  .bl_catalog {
    margin-top: 6.25rem;
  }
}

.bl_catalog_lists {
  display: grid;
  grid-template-columns: 1fr;
  gap: 3.125rem;
  max-width: 23.4375rem;
  margin: 1.875rem auto;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .bl_catalog_lists {
    grid-template-columns: 1fr 1fr;
    max-width: 48.0625rem;
    text-align: left;
    gap: 3.75rem 1.25rem;
  }
}
@media screen and (min-width: 1000px) {
  .bl_catalog_lists {
    max-width: 100%;
    margin: 1.875rem 0 0;
  }
}

@media screen and (min-width: 1000px) {
  .bl_catalog_lists_item {
    display: flex;
  }
}

.bl_catalog_lists_item_ttl {
  margin: 0 auto;
  width: 100%;
  max-width: 13.125rem;
}
@media screen and (min-width: 769px) {
  .bl_catalog_lists_item_ttl {
    margin: 0;
  }
}
.bl_catalog_lists_item_ttl img {
  width: 100%;
  max-width: 13.125rem;
}
.bl_catalog_lists_item_ttl a {
  transition: opacity 0.3s ease;
  cursor: pointer;
}
.bl_catalog_lists_item_ttl a:hover {
  opacity: 0.5;
}

.bl_catalog_lists {
  padding: 0;
}

.bl_catalog_lists_item_detail {
  margin-top: 0.625rem;
}
@media screen and (min-width: 1000px) {
  .bl_catalog_lists_item_detail {
    margin-top: 0;
    padding-left: 1.875rem;
  }
}
.bl_catalog_lists_item_detail + .bl_catalog_lists_item_detail {
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .bl_catalog_lists_item_detail + .bl_catalog_lists_item_detail {
    margin-top: 1.875rem;
  }
}

.bl_catalog_lists_item_detail_ttl {
  margin: 0;
  font-size: 1.125rem;
}
@media screen and (min-width: 769px) {
  .bl_catalog_lists_item_detail_ttl {
    font-size: 1.25rem;
  }
}

.bl_catalog_lists_item_detail_lists {
  font-size: 1rem;
  padding: 0;
  margin-top: 0.375rem;
}
@media screen and (min-width: 769px) {
  .bl_catalog_lists_item_detail_lists {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 1000px) {
  .bl_catalog_lists_item_detail_lists {
    margin-top: 0.625rem;
  }
}
.bl_catalog_lists_item_detail_lists li + li {
  margin-top: 0.625rem;
}
@media screen and (min-width: 769px) {
  .bl_catalog_lists_item_detail_lists li + li {
    margin-top: 0.75rem;
  }
}
.bl_catalog_lists_item_detail_lists .bl_catalog_lists_item_detail_notes {
  font-size: 0.875rem;
  margin-top: 1.25rem;
}

.swiper-container {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
}
.swiper-container img,
.swiper-container video {
  width: 100%;
  max-width: 100%;
  display: block;
  object-fit: cover;
}
.swiper-container .swiper-slide-wrap {
  height: 100%;
}
.swiper-container .swiper-slide-wrap p {
  margin: 0;
  padding: 0;
}
.swiper-container .video {
  height: 100%;
}
.swiper-container .swiper-button-prev,
.swiper-container .swiper-button-next {
  color: var(--swiperButton) !important;
}
.swiper-container .not_url {
  pointer-events: none;
}
.swiper-container .sp_only {
  display: block;
}
@media screen and (min-width: 769px) {
  .swiper-container .sp_only {
    display: none;
  }
}
.swiper-container .pc_only {
  display: none;
}
@media screen and (min-width: 769px) {
  .swiper-container .pc_only {
    display: block;
  }
}
.swiper-container .item_hidden {
  display: none;
}

.swiper-pagination .swiper-pagination-bullet {
  background-color: var(--paginationColor);
}
.swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--paginationColorActive) !important;
}

.no_swiper_img img {
  width: 100%;
  max-width: 100%;
}

.W1200Width {
  width: 100%;
  max-width: 1200px;
}

.lowerMv {
  position: relative;
  width: 100%;
  aspect-ratio: 2.1666666667;
}
@media screen and (min-width: 769px) {
  .lowerMv {
    aspect-ratio: 7.1111111111;
  }
}
.lowerMv .img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}
.lowerMv .lowerMv__inner {
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.lowerMv .lowerMv__title {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 1.6875rem;
  margin-left: 1.25rem;
}
@media screen and (min-width: 769px) {
  .lowerMv .lowerMv__title {
    font-size: 2.1875rem;
  }
}
.lowerMv .lowerMv__title .lowerMv__subTitle {
  font-size: 0.9375rem;
  color: #C7000A;
  text-transform: uppercase;
}

.instruction-manual-page {
  padding-top: 60px;
}
@media screen and (min-width: 769px) {
  .instruction-manual-page {
    padding-top: 100px;
  }
}
.instruction-manual-page h1 {
  padding: 0 20px;
}

.SearchFormTab .form__name {
  font-weight: 700;
}
.SearchFormTab #form01 .form__name {
  color: #186747;
  border-bottom-color: rgba(24, 103, 71, 0.5);
}
.SearchFormTab #form01 .submitBtn {
  background-color: #186747;
}
.SearchFormTab #form02 .BigCatregory:first-of-type {
  padding-top: 40px;
  border-top: 5px solid #86C03F;
}
.SearchFormTab #form02 .form__name {
  color: #746113;
  border-bottom-color: rgba(116, 97, 19, 0.5);
}
.SearchFormTab #form02 .submitBtn {
  background-color: #746113;
}
.SearchFormTab .BigCatregory > p {
  font-size: 1.125rem;
}
.SearchFormTab .BigCatregory label {
  margin: 0;
}
.SearchFormTab button[type=submit],
.SearchFormTab .submitBtn,
.SearchFormTab .clearBtn {
  cursor: pointer;
  transition: opacity 0.8s ease-out;
}
.SearchFormTab button[type=submit]:hover,
.SearchFormTab .submitBtn:hover,
.SearchFormTab .clearBtn:hover {
  opacity: 0.7;
}

.radioList {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem 1.25rem;
  line-height: 2;
}
.radioList li label {
  display: flex;
  align-items: center;
}
.radioList li label span {
  margin-left: 0.5em;
}
.radioList li input[type=radio] {
  margin: 0;
}

.SearchResultWrap:not([plTable]) th:nth-child(2), .SearchResultWrap:not([oiTable]) th:nth-child(2) {
  width: 300px;
}
.SearchResultWrap:not([plTable]) th:nth-child(3), .SearchResultWrap:not([oiTable]) th:nth-child(3) {
  width: 20em;
}
.SearchResultWrap:not([plTable]) th:last-child, .SearchResultWrap:not([oiTable]) th:last-child {
  width: 80px;
}
.SearchResultWrap.oiTable th:last-child {
  width: 120px;
}
.SearchResultWrap td {
  text-align: left;
}
.SearchResultWrap td.PartsListDL {
  text-align: center;
}

#searchResults {
  padding-top: 80px;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 25px 0;
}

.SearchFormTab .clearBtn,
.SearchFormTab .submitBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 200px;
  height: 40px;
  font-size: 17px;
  border: 0;
  color: white;
}
.SearchFormTab .submitBtn {
  cursor: pointer;
  background: #86C03F;
}
.SearchFormTab .clearBtn {
  background: #aaa !important;
}

.SearchFormTab button[type=submit] {
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

.frontLoader {
  position: relative;
  color: #fff;
  background-color: #000;
  font-size: 0.875rem;
  margin-top: 3.75rem;
  padding-top: 0 !important;
  padding-bottom: 3.75rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  background-image: url("../images/frontLoader/sp_top_bg@2x.png");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: contain;
}
@media screen and (min-width: 600px) {
  .frontLoader {
    background-image: url("../images/frontLoader/top_bg@2x.png");
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader {
    font-size: 1.125rem;
    margin-top: 6.25rem;
    padding-bottom: 12.5rem;
  }
}
.frontLoader * {
  margin: 0;
  padding: 0;
  font-family: "Zen Kaku Gothic Antique", sans-serif;
}
.frontLoader .wrapper {
  max-width: 94.375rem;
  margin: 0 auto;
}
.frontLoader .wrapperVer2 {
  max-width: 95.625rem;
  margin: 0 auto;
}
.frontLoader .wrapperRight {
  position: relative;
  max-width: 80rem;
  margin-left: auto;
}
@media screen and (min-width: 1200px) {
  .frontLoader .wrapperRight {
    padding-left: 1.875rem;
  }
}
.frontLoader .container {
  max-width: 77.5rem;
  margin: 0 auto;
}
.frontLoader .containerVer2 {
  max-width: 41.5rem;
  margin: 0 auto;
}
.frontLoader .containerVer3 {
  max-width: 75rem;
  margin: 0 auto;
  padding: 0;
}
@media screen and (min-width: 1200px) {
  .frontLoader .containerVer3 {
    padding: 0 1.25rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sp_only {
    display: none;
  }
}
.frontLoader .pc_only {
  display: none;
}
@media screen and (min-width: 1200px) {
  .frontLoader .pc_only {
    display: block;
  }
}
.frontLoader h2 {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 100;
}
.frontLoader h2,
.frontLoader h3,
.frontLoader h4 {
  text-transform: uppercase;
}
.frontLoader img {
  width: 100%;
  max-width: 100%;
  height: auto;
}
.frontLoader a {
  transition: all 0.5s ease;
}
.frontLoader a:hover {
  opacity: 0.5;
}
.frontLoader ._anchor {
  margin-top: -3.75rem;
  padding-top: 3.75rem;
}
@media screen and (min-width: 769px) {
  .frontLoader ._anchor {
    margin-top: -6.25rem;
    padding-top: 6.25rem;
  }
}

.frontLoader .fl__con {
  padding-top: 2.5rem;
}
@media screen and (min-width: 600px) {
  .frontLoader .fl__con {
    padding-top: 6.25rem;
  }
}
@media screen and (min-width: 769px) {
  .frontLoader .fl__con {
    padding-top: 3.75rem;
  }
}
.frontLoader .fl__con--nav--ul {
  display: flex;
  margin-left: -1.25rem;
  justify-content: space-around;
}
@media screen and (min-width: 600px) {
  .frontLoader .fl__con--nav--ul {
    justify-content: end;
  }
}
@media screen and (min-width: 769px) {
  .frontLoader .fl__con--nav--ul {
    margin-left: -2.5rem;
  }
}
.frontLoader .fl__con--nav--ul li {
  padding-left: 1.25rem;
}
@media screen and (min-width: 769px) {
  .frontLoader .fl__con--nav--ul li {
    padding-left: 2.5rem;
  }
}
.frontLoader .fl__con--nav--ul a {
  color: #fff;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
}
.frontLoader .fl__con--nav--ul a svg {
  width: 1rem;
  margin: 0 auto;
  margin-top: 0.75rem;
}
@media screen and (min-width: 769px) {
  .frontLoader .fl__con--nav--ul a svg {
    width: 1.3125rem;
  }
}
.frontLoader .fl__con--nav--ul .en {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.625rem;
  font-weight: 200;
  font-style: italic;
  text-transform: uppercase;
  letter-spacing: -0.025em;
}
@media screen and (min-width: 769px) {
  .frontLoader .fl__con--nav--ul .en {
    font-size: 2.5rem;
    letter-spacing: 0.1em;
  }
}
.frontLoader .fl__con--nav--ul .jp {
  font-family: "Zen Kaku Gothic Antique", sans-serif;
  font-size: 1rem;
}
@media screen and (min-width: 769px) {
  .frontLoader .fl__con--nav--ul .jp {
    font-size: 1.125rem;
  }
}
.frontLoader .section__Title {
  padding-bottom: 5.375rem;
}
.frontLoader .section__TitleVer2 {
  padding-bottom: 24.875rem;
}
.frontLoader .section__Title,
.frontLoader .section__TitleVer2 {
  position: relative;
  background-color: #A20008;
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  padding-right: calc(50vw - 50%);
  padding-left: calc(50vw - 50%);
  position: relative;
}
@media screen and (min-width: 1200px) {
  .frontLoader .section__Title,
  .frontLoader .section__TitleVer2 {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .section__Title .container,
  .frontLoader .section__TitleVer2 .container {
    position: relative;
  }
}
.frontLoader .section__Title .container .section__Title--inner,
.frontLoader .section__Title .container .section__Title--innerVer2,
.frontLoader .section__TitleVer2 .container .section__Title--inner,
.frontLoader .section__TitleVer2 .container .section__Title--innerVer2 {
  margin-right: calc(50% - 50vw);
}
.frontLoader .section__Title .container .section__Title--inner .en,
.frontLoader .section__Title .container .section__Title--innerVer2 .en,
.frontLoader .section__TitleVer2 .container .section__Title--inner .en,
.frontLoader .section__TitleVer2 .container .section__Title--innerVer2 .en {
  position: absolute;
  top: -1.875rem;
  left: 0.625rem;
  font-size: 3.75rem;
  font-style: italic;
  font-weight: 100;
  line-height: 1.1;
  font-family: "Roboto Condensed", sans-serif;
}
@media screen and (min-width: 1200px) {
  .frontLoader .section__Title .container .section__Title--inner .en,
  .frontLoader .section__Title .container .section__Title--innerVer2 .en,
  .frontLoader .section__TitleVer2 .container .section__Title--inner .en,
  .frontLoader .section__TitleVer2 .container .section__Title--innerVer2 .en {
    font-size: 6.25rem;
    top: -4.875rem;
    line-height: normal;
  }
}
.frontLoader .section__Title .container .section__Title--inner .jp,
.frontLoader .section__Title .container .section__Title--innerVer2 .jp,
.frontLoader .section__TitleVer2 .container .section__Title--inner .jp,
.frontLoader .section__TitleVer2 .container .section__Title--innerVer2 .jp {
  font-size: 0.9375rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0.8125rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .section__Title .container .section__Title--inner .jp,
  .frontLoader .section__Title .container .section__Title--innerVer2 .jp,
  .frontLoader .section__TitleVer2 .container .section__Title--inner .jp,
  .frontLoader .section__TitleVer2 .container .section__Title--innerVer2 .jp {
    font-size: 1.5rem;
    padding-left: 1.25rem;
  }
}
.frontLoader .section__Title .container .section__Title--inner ._b,
.frontLoader .section__Title .container .section__Title--innerVer2 ._b,
.frontLoader .section__TitleVer2 .container .section__Title--inner ._b,
.frontLoader .section__TitleVer2 .container .section__Title--innerVer2 ._b {
  font-size: 1.3125rem;
  line-height: 1.8;
  margin-top: 1.25rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .section__Title .container .section__Title--inner ._b,
  .frontLoader .section__Title .container .section__Title--innerVer2 ._b,
  .frontLoader .section__TitleVer2 .container .section__Title--inner ._b,
  .frontLoader .section__TitleVer2 .container .section__Title--innerVer2 ._b {
    font-size: 2rem;
  }
}
.frontLoader .section__Title .container .section__Title--inner ._n,
.frontLoader .section__Title .container .section__Title--innerVer2 ._n,
.frontLoader .section__TitleVer2 .container .section__Title--inner ._n,
.frontLoader .section__TitleVer2 .container .section__Title--innerVer2 ._n {
  font-size: 0.875rem;
  margin-top: 0.9375rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .section__Title .container .section__Title--inner ._n,
  .frontLoader .section__Title .container .section__Title--innerVer2 ._n,
  .frontLoader .section__TitleVer2 .container .section__Title--inner ._n,
  .frontLoader .section__TitleVer2 .container .section__Title--innerVer2 ._n {
    font-size: 1.25rem;
    margin-top: 1.625rem;
  }
}
.frontLoader .section__Title .container .section__Title--inner .section__Title--line,
.frontLoader .section__Title .container .section__Title--innerVer2 .section__Title--line,
.frontLoader .section__TitleVer2 .container .section__Title--inner .section__Title--line,
.frontLoader .section__TitleVer2 .container .section__Title--innerVer2 .section__Title--line {
  display: block;
  background-color: #fff;
  width: 63%;
  height: 0.0625rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .section__Title .container .section__Title--inner .section__Title--line,
  .frontLoader .section__Title .container .section__Title--innerVer2 .section__Title--line,
  .frontLoader .section__TitleVer2 .container .section__Title--inner .section__Title--line,
  .frontLoader .section__TitleVer2 .container .section__Title--innerVer2 .section__Title--line {
    width: 87%;
  }
}
.frontLoader .section__Title .container .section__Title--inner,
.frontLoader .section__TitleVer2 .container .section__Title--inner {
  padding: 2.6875rem 0 0;
}
@media screen and (min-width: 1200px) {
  .frontLoader .section__Title .container .section__Title--inner,
  .frontLoader .section__TitleVer2 .container .section__Title--inner {
    padding: 4.0625rem 0 8.125rem;
  }
}
.frontLoader .section__Title .container .section__Title--innerVer2,
.frontLoader .section__TitleVer2 .container .section__Title--innerVer2 {
  padding: 6.6875rem 0 0.625rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .section__Title .container .section__Title--innerVer2,
  .frontLoader .section__TitleVer2 .container .section__Title--innerVer2 {
    padding: 4.0625rem 0 8.75rem;
  }
}
.frontLoader .section__Title .image01,
.frontLoader .section__Title .image02,
.frontLoader .section__TitleVer2 .image01,
.frontLoader .section__TitleVer2 .image02 {
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  text-align: center;
}
@media screen and (min-width: 1200px) {
  .frontLoader .section__Title .image01,
  .frontLoader .section__Title .image02,
  .frontLoader .section__TitleVer2 .image01,
  .frontLoader .section__TitleVer2 .image02 {
    margin: 0;
  }
}
.frontLoader .section__Title .image01 img,
.frontLoader .section__Title .image02 img,
.frontLoader .section__TitleVer2 .image01 img,
.frontLoader .section__TitleVer2 .image02 img {
  max-width: 23.4375rem;
}
@media screen and (min-width: 600px) {
  .frontLoader .section__Title .image01 img,
  .frontLoader .section__Title .image02 img,
  .frontLoader .section__TitleVer2 .image01 img,
  .frontLoader .section__TitleVer2 .image02 img {
    max-width: 100%;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .section__Title .image01 img,
  .frontLoader .section__Title .image02 img,
  .frontLoader .section__TitleVer2 .image01 img,
  .frontLoader .section__TitleVer2 .image02 img {
    position: absolute;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .section__Title .image01 img,
  .frontLoader .section__TitleVer2 .image01 img {
    max-width: 31.25rem;
    top: -8.75rem;
    right: 0;
  }
}
@media screen and (min-width: 1920px) {
  .frontLoader .section__Title .image01 img,
  .frontLoader .section__TitleVer2 .image01 img {
    max-width: 52.3125rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .section__Title .image02 img,
  .frontLoader .section__TitleVer2 .image02 img {
    max-width: 48.75rem;
    top: 0;
    right: 0;
  }
}
.frontLoader .section__Title .section__Title--ul,
.frontLoader .section__Title .section__Title--ulVer2,
.frontLoader .section__TitleVer2 .section__Title--ul,
.frontLoader .section__TitleVer2 .section__Title--ulVer2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.6875rem 0.75rem;
  position: absolute;
  left: 0;
  padding: 0 0.625rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .section__Title .section__Title--ul,
  .frontLoader .section__Title .section__Title--ulVer2,
  .frontLoader .section__TitleVer2 .section__Title--ul,
  .frontLoader .section__TitleVer2 .section__Title--ulVer2 {
    gap: 1.125rem;
    padding: 0;
  }
}
.frontLoader .section__Title .section__Title--ul figcaption,
.frontLoader .section__Title .section__Title--ulVer2 figcaption,
.frontLoader .section__TitleVer2 .section__Title--ul figcaption,
.frontLoader .section__TitleVer2 .section__Title--ulVer2 figcaption {
  font-size: 0.875rem;
  margin-top: 0.375rem;
  font-weight: 500;
  letter-spacing: -0.08em;
}
@media screen and (min-width: 1200px) {
  .frontLoader .section__Title .section__Title--ul figcaption,
  .frontLoader .section__Title .section__Title--ulVer2 figcaption,
  .frontLoader .section__TitleVer2 .section__Title--ul figcaption,
  .frontLoader .section__TitleVer2 .section__Title--ulVer2 figcaption {
    font-size: 1rem;
    margin-top: 0.875rem;
    letter-spacing: normal;
  }
}
.frontLoader .section__Title .section__Title--ul,
.frontLoader .section__TitleVer2 .section__Title--ul {
  bottom: -6.25rem;
  max-width: 32.125rem;
}
@media screen and (min-width: 600px) {
  .frontLoader .section__Title .section__Title--ul,
  .frontLoader .section__TitleVer2 .section__Title--ul {
    bottom: -8.9375rem;
  }
}
.frontLoader .section__Title .section__Title--ulVer2,
.frontLoader .section__TitleVer2 .section__Title--ulVer2 {
  bottom: -12rem;
  max-width: 48.75rem;
}
@media screen and (min-width: 600px) {
  .frontLoader .section__Title .section__Title--ulVer2,
  .frontLoader .section__TitleVer2 .section__Title--ulVer2 {
    bottom: -13.125rem;
  }
}
@media screen and (min-width: 769px) {
  .frontLoader .section__Title .section__Title--ulVer2,
  .frontLoader .section__TitleVer2 .section__Title--ulVer2 {
    bottom: -20rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .section__Title .section__Title--ulVer2,
  .frontLoader .section__TitleVer2 .section__Title--ulVer2 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 1920px) {
  .frontLoader .section__Title .section__Title--ulVer2,
  .frontLoader .section__TitleVer2 .section__Title--ulVer2 {
    bottom: -13.125rem;
  }
}
.frontLoader .section__Title .section__Title--ulVer2 .section__Title--li figcaption p,
.frontLoader .section__TitleVer2 .section__Title--ulVer2 .section__Title--li figcaption p {
  font-weight: 400;
}
.frontLoader .section__Title .section__Title--ulVer2 .section__Title--li:nth-of-type(1) figcaption p,
.frontLoader .section__TitleVer2 .section__Title--ulVer2 .section__Title--li:nth-of-type(1) figcaption p {
  margin-top: 2.125rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .section__Title .section__Title--ulVer2 .section__Title--li:nth-of-type(1) figcaption p,
  .frontLoader .section__TitleVer2 .section__Title--ulVer2 .section__Title--li:nth-of-type(1) figcaption p {
    margin-top: 0;
  }
}
.frontLoader .section__Title .section__Title--ulVer2 .section__Title--li:nth-of-type(2) figcaption p, .frontLoader .section__Title .section__Title--ulVer2 .section__Title--li:nth-of-type(3) figcaption p,
.frontLoader .section__TitleVer2 .section__Title--ulVer2 .section__Title--li:nth-of-type(2) figcaption p,
.frontLoader .section__TitleVer2 .section__Title--ulVer2 .section__Title--li:nth-of-type(3) figcaption p {
  margin-top: 0.8125rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .section__Title .section__Title--ulVer2 .section__Title--li:nth-of-type(2) figcaption p, .frontLoader .section__Title .section__Title--ulVer2 .section__Title--li:nth-of-type(3) figcaption p,
  .frontLoader .section__TitleVer2 .section__Title--ulVer2 .section__Title--li:nth-of-type(2) figcaption p,
  .frontLoader .section__TitleVer2 .section__Title--ulVer2 .section__Title--li:nth-of-type(3) figcaption p {
    margin-top: 0;
  }
}

.frontLoader__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.attachment {
  margin-top: 6.25rem;
}
@media screen and (min-width: 1000px) {
  .attachment {
    margin-top: 12.5rem;
  }
}

.line__up--lead,
.attachment--lead {
  background-color: #A20008;
  margin-left: calc(50% - 50vw);
  margin-right: 0.625rem;
  padding: 1.125rem 0 0.9375rem;
  width: 100%;
  background-image: url("../images/frontLoader/sp_bg@2x.png");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (min-width: 1200px) {
  .line__up--lead,
  .attachment--lead {
    width: 97%;
    display: flex;
    align-items: center;
    padding: 3.125rem 0;
    background-image: url("../images/frontLoader/bg@2x.png");
  }
}
@media screen and (min-width: 1920px) {
  .line__up--lead,
  .attachment--lead {
    width: 82%;
  }
}
.line__up--lead h2,
.attachment--lead h2 {
  font-size: 2.5rem;
  font-style: italic;
  letter-spacing: 0.025em;
}
@media screen and (min-width: 1200px) {
  .line__up--lead h2,
  .attachment--lead h2 {
    font-size: 3.75rem;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
  }
}
.line__up--lead h2 .text,
.attachment--lead h2 .text {
  line-height: 1;
  padding-left: 2.8125rem;
  font-family: "Roboto Condensed", sans-serif;
}
@media screen and (min-width: 1200px) {
  .line__up--lead h2 .text,
  .attachment--lead h2 .text {
    padding-left: 0;
  }
}
.line__up--lead p,
.attachment--lead p {
  font-size: 0.875rem;
  line-height: 2;
  padding: 1.25rem 0 0 3.875rem;
}
@media screen and (min-width: 1200px) {
  .line__up--lead p,
  .attachment--lead p {
    font-size: 1.125rem;
    margin-left: 4.1875rem;
    padding: 0;
  }
}
.line__up--lead .line,
.attachment--lead .line {
  display: block;
  width: 9.375rem;
  height: 0.0625rem;
  background-color: #fff;
  margin-top: 0.3125rem;
}
@media screen and (min-width: 1200px) {
  .line__up--lead .line,
  .attachment--lead .line {
    width: 18.75rem;
    margin-top: 0;
    margin-right: 2.5rem;
  }
}

.attachment__title h3.en {
  position: relative;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.75rem;
  font-weight: 100;
  font-style: italic;
  z-index: 1;
}
@media screen and (min-width: 1200px) {
  .attachment__title h3.en {
    font-size: 2.5rem;
  }
}
.attachment__title h3.en .en_bg {
  position: absolute;
  top: -1.375rem;
  left: -0.75rem;
  z-index: -1;
}
@media screen and (min-width: 1200px) {
  .attachment__title h3.en .en_bg {
    top: -3.75rem;
    left: -5.125rem;
  }
}
.attachment__title h3.jp {
  font-size: 1rem;
  font-weight: 500;
  z-index: 10;
}
@media screen and (min-width: 1200px) {
  .attachment__title h3.jp {
    font-size: 1.5rem;
  }
}
.attachment__title .lineup__title {
  padding-left: 0.625rem;
}
@media screen and (min-width: 1200px) {
  .attachment__title .lineup__title {
    display: flex;
    align-items: center;
    padding-left: 1.4375rem;
  }
}
.attachment__title .attachment__title--ul._box {
  display: flex;
  gap: 0.75rem;
  width: 19rem;
  margin-top: 0.8125rem;
}
@media screen and (min-width: 1200px) {
  .attachment__title .attachment__title--ul._box {
    width: 27.1875rem;
    margin-left: 2.5rem;
    margin-top: 0;
  }
}
.attachment__title .attachment__title--ul._box .attachment__title--li {
  font-size: 0.625rem;
  border: solid 0.0625rem #fff;
  text-align: center;
  display: inline-block;
  padding: 0.25rem 0.875rem;
}
@media screen and (min-width: 1200px) {
  .attachment__title .attachment__title--ul._box .attachment__title--li {
    font-size: 1rem;
  }
}
.attachment__title .attachment__title--text {
  border-top: solid 0.0625rem #fff;
  margin-top: 1.125rem;
  padding: 1.5rem 0.625rem 0;
}
@media screen and (min-width: 1200px) {
  .attachment__title .attachment__title--text {
    margin-top: 1.375rem;
    padding: 1.5rem 0 0 1.4375rem;
    width: 100%;
    max-width: 51.0625rem;
  }
}
.attachment__title .attachment__title--text p {
  font-size: 0.875rem;
  line-height: 2;
  text-align: justify;
}
@media screen and (min-width: 1200px) {
  .attachment__title .attachment__title--text p {
    font-size: 1.125rem;
  }
}

.frontLoader .fl__kv {
  margin-left: auto;
  padding: 2.625rem 0 33.5rem;
  width: 22.1875rem;
}
@media screen and (min-width: 600px) {
  .frontLoader .fl__kv {
    padding: 2.5rem 0 6.25rem;
  }
}
@media screen and (min-width: 769px) {
  .frontLoader .fl__kv {
    width: 50%;
    padding: 6.25rem 0 12.5rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .fl__kv {
    padding: 12.5rem 0 33.5rem;
  }
}
@media screen and (min-width: 1366px) {
  .frontLoader .fl__kv {
    padding: 10rem 0 33.5rem;
  }
}
@media screen and (min-width: 1500px) {
  .frontLoader .fl__kv {
    padding: 15.625rem 0 33.5rem;
  }
}
.frontLoader .fl__kv h2 {
  font-size: 2.5rem;
  line-height: 1.35;
}
@media screen and (min-width: 600px) {
  .frontLoader .fl__kv h2 {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .fl__kv h2 {
    font-size: 3.125rem;
  }
}
@media screen and (min-width: 1920px) {
  .frontLoader .fl__kv h2 {
    font-size: 3.75rem;
  }
}
.frontLoader .fl__kv h2 .text1 {
  font-size: 4.375rem;
}
@media screen and (min-width: 600px) {
  .frontLoader .fl__kv h2 .text1 {
    font-size: 3.125rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .fl__kv h2 .text1 {
    font-size: 5rem;
  }
}
@media screen and (min-width: 1920px) {
  .frontLoader .fl__kv h2 .text1 {
    font-size: 7.5rem;
  }
}
.frontLoader .fl__kv h2 .text2 {
  display: block;
  text-align: right;
}
@media screen and (min-width: 600px) {
  .frontLoader .fl__kv h2 .text2 {
    text-align: left;
    display: inline;
  }
}
.frontLoader .fl__kv h2,
.frontLoader .fl__kv h2 .text1,
.frontLoader .fl__kv h2 .text2 {
  font-family: "Zen Old Mincho", serif;
  font-weight: 100;
}
.frontLoader .fl__kv p {
  font-weight: 100;
  font-size: 1.25rem;
  line-height: 1;
  margin-top: 1.625rem;
}
@media screen and (min-width: 600px) {
  .frontLoader .fl__kv p {
    margin-top: 0.625rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .fl__kv p {
    font-size: 1.5rem;
    margin-top: 0;
  }
}
.frontLoader .sec1,
.frontLoader .sec3,
.frontLoader .sec4,
.frontLoader .sec5,
.frontLoader .sec6,
.frontLoader .sec7,
.frontLoader .sec8,
.frontLoader .sec9,
.frontLoader .sec10,
.frontLoader .sec11 {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec1,
  .frontLoader .sec3,
  .frontLoader .sec4,
  .frontLoader .sec5,
  .frontLoader .sec6,
  .frontLoader .sec7,
  .frontLoader .sec8,
  .frontLoader .sec9,
  .frontLoader .sec10,
  .frontLoader .sec11 {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
.frontLoader .sec1 {
  padding-top: 1.25rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec1 {
    padding-top: 2.5rem;
  }
}
.frontLoader .sec1 .lead {
  text-align: left;
  line-height: 2;
  letter-spacing: 0.3;
}
.frontLoader .sec1 .lead h2 {
  font-size: 2.5rem;
  font-style: italic;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec1 .lead h2 {
    font-size: 3.75rem;
  }
}
.frontLoader .sec1 .lead h2 .line {
  display: block;
  width: 5.5rem;
  background-color: #fff;
  height: 0.0625rem;
  margin-left: 0.8125rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec1 .lead h2 .line {
    width: 18.75rem;
    margin-left: 1.625rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec1 .lead p {
    padding-left: 1.25rem;
  }
}
.frontLoader .sec1 .sec1__lists {
  margin-top: 3.125rem;
  padding: 0;
}
@media screen and (min-width: 769px) {
  .frontLoader .sec1 .sec1__lists {
    margin-top: 5rem;
  }
}
.frontLoader .sec1 .sec1__lists--item {
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 calc(50% - 50vw);
  padding: 1.5rem calc(50vw - 50%) 2.5rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec1 .sec1__lists--item {
    padding: 3.125rem calc(50vw - 50%) 2.5rem;
  }
}
.frontLoader .sec1 .sec1__lists--item:nth-of-type(1) {
  background-image: url("../images/frontLoader/section1/1/sp_bg.jpg");
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec1 .sec1__lists--item:nth-of-type(1) {
    background-image: url("../images/frontLoader/section1/1/bg.jpg");
  }
}
.frontLoader .sec1 .sec1__lists--item:nth-of-type(2) {
  background-image: url("../images/frontLoader/section1/2/sp_bg.jpg");
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec1 .sec1__lists--item:nth-of-type(2) {
    background-image: url("../images/frontLoader/section1/2/bg.jpg");
  }
}
.frontLoader .sec1 .sec1__lists--item:nth-of-type(3) {
  background-image: url("../images/frontLoader/section1/3/sp_bg.jpg");
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec1 .sec1__lists--item:nth-of-type(3) {
    background-image: url("../images/frontLoader/section1/3/bg.jpg");
  }
}
.frontLoader .sec1 .sec1__lists--item:nth-of-type(4) {
  background-image: url("../images/frontLoader/section1/4/sp_bg.jpg");
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec1 .sec1__lists--item:nth-of-type(4) {
    background-image: url("../images/frontLoader/section1/4/bg.jpg");
  }
}
.frontLoader .sec1 .sec1__lists--item:nth-of-type(5) {
  background-image: url("../images/frontLoader/section1/5/sp_bg.jpg");
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec1 .sec1__lists--item:nth-of-type(5) {
    background-image: url("../images/frontLoader/section1/5/bg.jpg");
  }
}
.frontLoader .sec1 .sec1__lists--item:not(:first-child) {
  padding-top: 1.5rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec1 .sec1__lists--item:not(:first-child) {
    padding-top: 4.375rem;
  }
}
.frontLoader .sec1 .sec1__lists--item .inner {
  position: relative;
  padding-top: 3.5rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec1 .sec1__lists--item .inner {
    padding-top: 5.625rem;
  }
}
.frontLoader .sec1 .sec1__lists--item .inner h3 {
  position: absolute;
  top: 0;
}
.frontLoader .sec1 .sec1__lists--item .inner .title1 img {
  width: 6.8125rem;
  padding-left: 1.25rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec1 .sec1__lists--item .inner .title1 img {
    width: 10.9375rem;
    padding-left: 2.0625rem;
  }
}
.frontLoader .sec1 .sec1__lists--item .inner .title2 img {
  width: 7.5625rem;
  padding-left: 0.625rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec1 .sec1__lists--item .inner .title2 img {
    width: 12.0625rem;
  }
}
.frontLoader .sec1 .sec1__lists--item .inner .title3 img {
  width: 13.625rem;
  padding-left: 0.625rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec1 .sec1__lists--item .inner .title3 img {
    width: 22.125rem;
  }
}
.frontLoader .sec1 .sec1__lists--item .inner .title4 img {
  width: 7.5625rem;
  padding-left: 0.625rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec1 .sec1__lists--item .inner .title4 img {
    width: 12.125rem;
  }
}
.frontLoader .sec1 .sec1__lists--item .inner .title5 img {
  width: 12.375rem;
  padding-left: 0.625rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec1 .sec1__lists--item .inner .title5 img {
    width: 20.0625rem;
  }
}
.frontLoader .sec1 .contentsBox {
  display: grid;
  grid-template-columns: 1fr;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec1 .contentsBox {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec1 .videoBox {
    width: 100%;
    max-width: 35.125rem;
    padding-right: 3.625rem;
  }
}
.frontLoader .sec1 .videoBox iframe {
  width: 100%;
  height: 100%;
}
.frontLoader .sec1 .itemBox {
  margin-top: 1.6875rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec1 .itemBox {
    margin-top: 0;
  }
}
.frontLoader .sec1 .itemBox h4 {
  font-size: 1.75rem;
  font-weight: 200;
  display: flex;
  align-items: center;
  font-style: italic;
  letter-spacing: -0.05em;
  font-family: "Roboto Condensed", sans-serif;
  color: #272727;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec1 .itemBox h4 {
    font-size: 2.5rem;
    letter-spacing: normal;
  }
}
.frontLoader .sec1 .itemBox h4 span {
  display: block;
  background-color: #272727;
  width: 3.875rem;
  height: 0.0625rem;
  margin-left: 0.9375rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec1 .itemBox h4 span {
    width: 7.75rem;
  }
}
.frontLoader .sec1 .itemBox__link {
  display: flex;
  align-items: center;
}
.frontLoader .sec1 .itemBox__ul {
  margin-top: 0.625rem;
  padding: 0.625rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem 0.6875rem;
  background-color: #fff;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec1 .itemBox__ul {
    margin-top: 1.75rem;
  }
}
.frontLoader .sec1 .itemBox__ul p {
  display: flex;
  align-items: center;
  color: #272727;
}
.frontLoader .sec1 .itemBox__ul p svg {
  margin-left: 0.375rem;
}
.frontLoader .line__up {
  padding-top: 6.375rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .line__up {
    padding-top: 13.125rem;
  }
}
.frontLoader .sec2 {
  margin-top: 5rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec2 {
    margin-top: 13.625rem;
  }
}
.frontLoader .sec2 .feature {
  margin-top: 11.4375rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec2 .feature {
    margin-top: 16.875rem;
  }
}
.frontLoader .sec2 .feature .feature__ul {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2.3125rem 0;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec2 .feature .feature__ul {
    grid-template-columns: 1fr 1fr;
    gap: 3.125rem 0;
    padding: 0 1.25rem;
  }
}
.frontLoader .sec2 .feature .feature__li:nth-of-type(1) {
  order: 1;
}
.frontLoader .sec2 .feature .feature__li:nth-of-type(2) {
  order: 4;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec2 .feature .feature__li:nth-of-type(2) {
    order: 2;
  }
}
.frontLoader .sec2 .feature .feature__li:nth-of-type(3) {
  order: 2;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec2 .feature .feature__li:nth-of-type(3) {
    order: 3;
  }
}
.frontLoader .sec2 .feature .feature__li:nth-of-type(4) {
  order: 5;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec2 .feature .feature__li:nth-of-type(4) {
    order: 4;
  }
}
.frontLoader .sec2 .feature .feature__li:nth-of-type(5) {
  order: 3;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec2 .feature .feature__li:nth-of-type(5) {
    order: 5;
  }
}
.frontLoader .sec2 .feature .feature__li:nth-of-type(6) {
  order: 6;
}
.frontLoader .sec2 .feature .feature__li ._link {
  position: relative;
  display: block;
  color: #D3000A;
  background-color: #000;
  border: solid 0.0625rem #D3000A;
  text-align: center;
  padding: 1.125rem 0.625rem;
  box-shadow: 0 0 0.625rem #A20008;
  margin-top: 1.875rem;
}
@media screen and (min-width: 600px) {
  .frontLoader .sec2 .feature .feature__li ._link {
    max-width: 17.375rem;
  }
}
.frontLoader .sec2 .feature .feature__li ._link svg {
  position: absolute;
  width: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  right: 1.25rem;
}
.frontLoader .sec2 .feature .feature__li--inner {
  display: flex;
  justify-content: space-between;
}
@media screen and (min-width: 600px) {
  .frontLoader .sec2 .feature .feature__li--inner {
    justify-content: flex-start;
  }
}
@media screen and (min-width: 600px) {
  .frontLoader .sec2 .feature .feature__li--inner ._item02 {
    margin-left: 1.25rem;
  }
}
.frontLoader .sec2 .feature .feature__li--inner ._item02._img1 {
  width: 10rem;
  margin-top: 2.5rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec2 .feature .feature__li--inner ._item02._img1 {
    width: 13.875rem;
    margin-top: 0;
  }
}
.frontLoader .sec2 .feature .feature__li--inner ._item02._img2 {
  width: 10rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec2 .feature .feature__li--inner ._item02._img2 {
    width: 15.9375rem;
  }
}
.frontLoader .sec2 .feature .feature__li--inner ._item02._img3 {
  width: 10.0625rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec2 .feature .feature__li--inner ._item02._img3 {
    width: 14.9375rem;
  }
}
.frontLoader .sec2 .feature .feature__li--inner ._item02._img4 {
  width: 10.125rem;
  margin-top: 4.0625rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec2 .feature .feature__li--inner ._item02._img4 {
    width: 13.9375rem;
    margin-top: 1.625rem;
  }
}
.frontLoader .sec2 .feature h4 {
  display: inline-block;
  font-size: 1.125rem;
  background-color: #A20008;
  text-align: center;
  padding: 0.625rem 0.8125rem;
  font-weight: 400;
  letter-spacing: -0.025em;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec2 .feature h4 {
    font-size: 1.5rem;
    padding: 0.875rem 3.75rem;
    letter-spacing: normal;
  }
}
.frontLoader .sec2 .feature p {
  font-size: 0.875rem;
  line-height: 2;
  letter-spacing: 0.025em;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec2 .feature p {
    font-size: 1.125rem;
  }
}
.frontLoader .sec2 .feature ._text {
  padding: 0.625rem 0 0 0.75rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec2 .feature ._text {
    padding: 0.75rem 0 0 2rem;
  }
}
.frontLoader .sec2 .feature ._text p {
  line-height: 1.7;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec2 .feature ._text p {
    line-height: 2;
  }
}
.frontLoader .sec2 .feature .notes {
  font-size: 0.75rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec2 .feature .notes {
    font-size: 0.875rem;
  }
}
.frontLoader .sec3 {
  position: relative;
  margin-top: 7.125rem;
  padding-bottom: 10.625rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 {
    margin-top: 14.0625rem;
    padding-bottom: 16.6875rem;
  }
}
.frontLoader .sec3 .section__Title--line {
  width: 87%;
}
.frontLoader .sec3 .numLists .numLists__wrap {
  position: relative;
  z-index: 1;
}
.frontLoader .sec3 .numLists .numLists__inner {
  margin-top: 0.75rem;
  width: 100%;
}
.frontLoader .sec3 .numLists .numLists__lead {
  font-size: 0.875rem;
  font-weight: 500;
  text-align: justify;
  line-height: 2;
  letter-spacing: 0.05em;
  padding-left: 0.625rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 .numLists .numLists__lead {
    font-size: 1rem;
    letter-spacing: normal;
    padding-left: 0;
  }
}
.frontLoader .sec3 .numLists .numLists__ul {
  padding: 0 0.625rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 .numLists .numLists__ul {
    padding: 0;
  }
}
.frontLoader .sec3 .numLists .numLists__ul .numLists__box {
  border: solid 0.0625rem #fff;
  padding: 0.875rem 1.25rem;
  line-height: 1.75;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 .numLists .numLists__ul .numLists__box {
    padding: 0.875rem 1.25rem;
  }
}
.frontLoader .sec3 .numLists .numLists__ul .numLists__box h4 {
  font-size: 1rem;
  font-weight: 500;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 .numLists .numLists__ul .numLists__box h4 {
    font-size: 1.25rem;
  }
}
.frontLoader .sec3 .numLists .numLists__ul .numLists__box p {
  font-weight: 400;
  font-size: 0.875rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 .numLists .numLists__ul .numLists__box p {
    font-size: 1rem;
  }
}
.frontLoader .sec3 .numLists .numLists__ul .numLists__box .numLists__box--notes {
  font-size: 0.75rem;
  display: block;
}
@media screen and (min-width: 1920px) {
  .frontLoader .sec3 .numLists .container {
    padding-left: 1.25rem;
  }
}
.frontLoader .sec3 ._one {
  margin-top: 14.5625rem;
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 769px) {
  .frontLoader .sec3 ._one {
    margin-top: 22.5rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 ._one {
    margin-top: 20.8125rem;
    padding-top: 12.5rem;
  }
}
.frontLoader .sec3 ._one .numLists__wrap {
  z-index: 1;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 ._one .numLists__wrap {
    padding-left: 1rem;
  }
}
.frontLoader .sec3 ._one .numLists__ul {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.25rem;
  margin-top: 1rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 ._one .numLists__ul {
    grid-template-columns: 1fr 1fr;
    margin-top: 1.375rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 ._one .numLists__inner {
    max-width: 43.375rem;
    padding-left: 1.25rem;
  }
}
.frontLoader .sec3 ._one .numLists__one--img {
  max-width: 97.5625rem;
  margin-right: calc(50% - 50vw);
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 ._one .numLists__one--img {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.frontLoader .sec3 ._two {
  position: relative;
  margin-top: -13.125rem;
  padding-top: 28.0625rem;
}
@media screen and (min-width: 600px) {
  .frontLoader .sec3 ._two {
    margin-top: -23.125rem;
    padding-top: 46.0625rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 ._two {
    margin-top: -16.25rem;
    padding-top: 28.125rem;
  }
}
@media screen and (min-width: 1500px) {
  .frontLoader .sec3 ._two {
    margin-top: -16.25rem;
    padding-top: 31.25rem;
  }
}
@media screen and (min-width: 1920px) {
  .frontLoader .sec3 ._two {
    margin-top: -24.375rem;
    padding-top: 34.375rem;
  }
}
.frontLoader .sec3 ._two .numLists__inner {
  width: 100%;
  max-width: 42.625rem;
  margin: 0 auto;
}
.frontLoader .sec3 ._two .numLists__lead {
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .frontLoader .sec3 ._two .numLists__lead {
    padding-left: 1.875rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 ._two .numLists__lead {
    padding-left: 11.25rem;
  }
}
@media screen and (min-width: 1920px) {
  .frontLoader .sec3 ._two .numLists__lead {
    padding-left: 0;
  }
}
.frontLoader .sec3 ._two .numLists__two--img {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 103.125rem;
  margin-left: calc(50% - 50vw);
}
@media screen and (min-width: 769px) {
  .frontLoader .sec3 ._two .numLists__two--img {
    margin-left: 0;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 ._two .numLists__two--img {
    margin-left: calc(50% - 50vw);
  }
}
.frontLoader .sec3 ._three {
  margin-top: 3.75rem;
}
@media screen and (min-width: 600px) {
  .frontLoader .sec3 ._three {
    margin-top: 13.75rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 ._three {
    margin-top: 0.3125rem;
  }
}
.frontLoader .sec3 ._three .wrapperRight {
  padding-top: 4.625rem;
}
@media screen and (min-width: 600px) {
  .frontLoader .sec3 ._three .wrapperRight {
    padding-top: 7.875rem;
  }
}
@media screen and (min-width: 1000px) {
  .frontLoader .sec3 ._three .wrapperRight {
    padding-top: 10rem;
  }
}
@media screen and (min-width: 1366px) {
  .frontLoader .sec3 ._three .wrapperRight {
    padding-top: 11.4375rem;
  }
}
@media screen and (min-width: 1500px) {
  .frontLoader .sec3 ._three .wrapperRight {
    padding-top: 11.75rem;
  }
}
.frontLoader .sec3 ._three .numLists__three--img {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 69.5625rem;
  margin-right: calc(50% - 50vw);
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 ._three .numLists__three--img {
    margin-right: 0;
  }
}
.frontLoader .sec3 ._three .numLists__lead {
  width: 13rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 ._three .numLists__lead {
    width: auto;
  }
}
.frontLoader .sec3 ._four {
  margin-top: 1.5625rem;
}
@media screen and (min-width: 600px) {
  .frontLoader .sec3 ._four {
    margin-top: 10rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 ._four {
    margin-top: 3.25rem;
  }
}
.frontLoader .sec3 ._four .wrapperRight {
  padding-top: 12.3125rem;
}
@media screen and (min-width: 600px) {
  .frontLoader .sec3 ._four .wrapperRight {
    padding-top: 20rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 ._four .wrapperRight {
    padding-top: 6.25rem;
  }
}
.frontLoader .sec3 ._four .numLists__four--img {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 71.3125rem;
}
.frontLoader .sec3 ._four .numLists__ul {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.25rem;
  margin-top: 1.25rem;
  width: 100%;
  max-width: 40.9375rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 ._four .numLists__ul {
    margin-top: 1.375rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 ._four .numLists__ul .numLists__box--notes {
    margin-top: 1.375rem;
  }
}
.frontLoader .sec3 ._five {
  position: relative;
  margin: 3.4375rem auto 0;
  padding-top: 4.5625rem;
  width: 100%;
  max-width: 95.625rem;
  z-index: 1;
}
@media screen and (min-width: 600px) {
  .frontLoader .sec3 ._five {
    margin: 6.25rem auto 0;
    padding-top: 7.75rem;
  }
}
@media screen and (min-width: 1366px) {
  .frontLoader .sec3 ._five {
    margin: 6.875rem auto 0;
    padding-top: 19.375rem;
  }
}
@media screen and (min-width: 1800px) {
  .frontLoader .sec3 ._five {
    margin: -10rem auto 0;
    padding-top: 19.375rem;
  }
}
@media screen and (min-width: 1920px) {
  .frontLoader .sec3 ._five {
    margin: -8.125rem auto 0;
  }
}
.frontLoader .sec3 ._five .numLists__inner {
  width: 100%;
  max-width: 42.625rem;
  margin: 0 auto;
}
.frontLoader .sec3 ._five .numLists__lead {
  margin-top: 1.25rem;
  width: 11rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 ._five .numLists__lead {
    width: auto;
  }
}
.frontLoader .sec3 ._five .numLists__five--img {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 40.9375rem;
  margin-right: calc(50% - 50vw);
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 ._five .numLists__five--img {
    margin-right: 0;
  }
}
.frontLoader .sec3 ._six {
  position: relative;
  margin: 1.875rem auto 0;
  padding-top: 12.9375rem;
  width: 100%;
  max-width: 95.625rem;
  z-index: 1;
}
@media screen and (min-width: 600px) {
  .frontLoader .sec3 ._six {
    margin: 10.625rem auto 0;
    padding-top: 21.25rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 ._six {
    margin: -2.5rem auto 0;
    padding-top: 11.25rem;
  }
}
.frontLoader .sec3 ._six .numLists__inner {
  width: 100%;
  max-width: 42.625rem;
  margin: 0 auto;
}
.frontLoader .sec3 ._six .numLists__lead {
  margin-top: 1.25rem;
}
.frontLoader .sec3 ._six .numLists__six--img {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 39.375rem;
  margin-right: calc(50% - 50vw);
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 ._six .numLists__six--img {
    margin-right: 0;
  }
}
.frontLoader .sec3 ._six .numLists__ul {
  margin-top: 1.375rem;
  width: 100%;
  max-width: 28.125rem;
}
.frontLoader .sec3 ._six .numLists__box p {
  display: flex;
}
.frontLoader .sec3 ._six .numLists__box p span {
  display: inline-block;
  padding-left: 0.375rem;
}
.frontLoader .sec3 ._seven {
  position: relative;
  margin: 2.6875rem auto 0;
  padding-top: 4.5625rem;
  width: 100%;
  max-width: 95.625rem;
}
@media screen and (min-width: 600px) {
  .frontLoader .sec3 ._seven {
    margin: 4.1875rem auto 0;
    padding-top: 7.5rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 ._seven {
    padding-top: 5.625rem;
  }
}
.frontLoader .sec3 ._seven .numLists__inner {
  width: 100%;
  max-width: 42.625rem;
  margin: 0 auto;
}
.frontLoader .sec3 ._seven .numLists__lead {
  margin-top: 1.25rem;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0.625rem;
  width: 16rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 ._seven .numLists__lead {
    width: auto;
    margin-left: 0;
    padding-right: 0;
  }
}
.frontLoader .sec3 ._seven .numLists__seven--img {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 43.125rem;
}
.frontLoader .sec3 .sec3__video {
  margin-top: 3.25rem;
}
@media screen and (min-width: 600px) {
  .frontLoader .sec3 .sec3__video {
    margin-top: 8.75rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 .sec3__video {
    margin-top: 3.5rem;
  }
}
.frontLoader .sec3 .sec3__video h3 {
  position: relative;
  font-weight: 100;
  padding-top: 2.9375rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 .sec3__video h3 {
    padding-top: 0;
  }
}
.frontLoader .sec3 .sec3__video h3 .en {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 2.5rem;
  font-style: italic;
  font-family: "Roboto Condensed", sans-serif;
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: calc(50% - 50vw);
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 .sec3__video h3 .en {
    position: static;
    font-size: 3.75rem;
    margin-right: 0;
  }
}
.frontLoader .sec3 .sec3__video h3 .en .line {
  display: block;
  background-color: #fff;
  width: 50%;
  height: 0.0625rem;
  width: 100%;
  max-width: 14.9375rem;
  margin: 0 0 0 0.9375rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 .sec3__video h3 .en .line {
    max-width: 18.75rem;
    margin: 0.75rem 0 0 1.75rem;
  }
}
.frontLoader .sec3 .sec3__video h3 .jp {
  font-size: 1.125rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 .sec3__video h3 .jp {
    font-size: 1.5rem;
  }
}
.frontLoader .sec3 .sec3__video .sec3__video--ul {
  margin-top: 1.5625rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem 0.875rem;
  width: 100%;
  max-width: 70rem;
  padding: 0 0.625rem 0;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 .sec3__video .sec3__video--ul {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1.6875rem 3rem;
    margin-top: 2.5rem;
    padding: 0 0 0 0.9375rem;
  }
}
.frontLoader .sec3 .sec3__video .sec3__video--ul .sec3__video--li {
  z-index: 1;
}
.frontLoader .sec3 .sec3__video .sec3__video--ul h4 {
  font-family: "Roboto Condensed", sans-serif;
  color: #D3000A;
  font-weight: 100;
}
.frontLoader .sec3 .sec3__video .sec3__video--ul h4 .en {
  font-size: 1.75rem;
  font-style: italic;
  font-family: "Roboto Condensed", sans-serif;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 .sec3__video .sec3__video--ul h4 .en {
    font-size: 2.5rem;
  }
}
.frontLoader .sec3 .sec3__video .sec3__video--ul h4 .ja {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
}
.frontLoader .sec3 .sec3__video .sec3__video--ul .youtube_link {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  border: solid 0.0625rem #D3000A;
  box-shadow: 0 0 0.625rem #A20008;
  margin-top: 0.5rem;
  min-height: 3.125rem;
}
@media screen and (min-width: 769px) {
  .frontLoader .sec3 .sec3__video .sec3__video--ul .youtube_link {
    min-height: 4rem;
  }
}
.frontLoader .sec3 .sec3__video .sec3__video--ul .youtube_link_wrap {
  display: block;
  width: 100%;
  padding: 0.375rem 0.9375rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 .sec3__video .sec3__video--ul .youtube_link_wrap {
    padding: 0.5625rem 1.125rem;
  }
}
.frontLoader .sec3 .sec3__video .sec3__video--ul .youtube_link_wrap .look_movie,
.frontLoader .sec3 .sec3__video .sec3__video--ul .youtube_link_wrap .click_here,
.frontLoader .sec3 .sec3__video .sec3__video--ul .youtube_link_wrap .coming_soon {
  display: block;
  font-weight: 500;
  letter-spacing: 0.03em;
  text-align: center;
}
.frontLoader .sec3 .sec3__video .sec3__video--ul .youtube_link_wrap .look_movie {
  font-size: 0.875rem;
  color: #fff;
}
@media screen and (min-width: 769px) {
  .frontLoader .sec3 .sec3__video .sec3__video--ul .youtube_link_wrap .look_movie {
    font-size: 1.125rem;
  }
}
.frontLoader .sec3 .sec3__video .sec3__video--ul .youtube_link_wrap .click_here {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: #D3000A;
  margin-top: 0.25rem;
}
.frontLoader .sec3 .sec3__video .sec3__video--ul .youtube_link_wrap .coming_soon {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 0.875rem;
  text-transform: uppercase;
  color: #D3000A;
}
.frontLoader .sec3 .sec3__video .sec3__video--ul .sec3__video--box svg {
  position: absolute;
  width: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  right: 0.4375rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 .sec3__video .sec3__video--ul .sec3__video--box svg {
    right: 1.25rem;
  }
}
.frontLoader .sec3 .sec3__video .sec3__video--ul .sec3__video--box img {
  width: 5.625rem;
  margin: 0 auto;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 .sec3__video .sec3__video--ul .sec3__video--box img {
    width: 7.5rem;
  }
}
.frontLoader .sec3 ._bg-img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 18.1875rem;
  margin-right: calc(50% - 50vw);
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec3 ._bg-img {
    max-width: 63.3125rem;
  }
}
.frontLoader .sec4 .sec4__lists--ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 10.4375rem 10.4375rem 13.375rem;
  gap: 1.25rem 0.875rem;
  margin-top: 2rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec4 .sec4__lists--ul {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 17.375rem 17.375rem;
    gap: 1.875rem;
    margin-top: 4.8125rem;
    padding: 0 1.25rem;
  }
}
.frontLoader .sec4 .sec4__lists--li {
  border: solid 0.0625rem #D3000A;
  padding-top: 0.75rem;
  background-color: #D9D9D9;
  color: #fff;
}
.frontLoader .sec4 .sec4__lists--li:nth-of-type(5), .frontLoader .sec4 .sec4__lists--li:nth-of-type(6) {
  padding-top: 1.25rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec4 .sec4__lists--li:nth-of-type(5), .frontLoader .sec4 .sec4__lists--li:nth-of-type(6) {
    padding-top: 0.75rem;
  }
}
.frontLoader .sec4 .sec4__lists--li ._name {
  font-size: 0.625rem;
  padding: 0.1875rem 0 0 0.625rem;
  color: #000000;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec4 .sec4__lists--li ._name {
    font-size: 0.875rem;
    padding: 0.375rem 0 0 1.125rem;
  }
}
.frontLoader .sec4 .sec4__lists--li .sec4__lists--img {
  width: 100%;
  margin: 0 auto;
}
.frontLoader .sec4 .sec4__lists--li ._item01 {
  max-width: 6.25rem;
  margin-top: 0.6875rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec4 .sec4__lists--li ._item01 {
    max-width: 13.9375rem;
  }
}
.frontLoader .sec4 .sec4__lists--li ._item02 {
  max-width: 5.3125rem;
  margin-left: 1.6875rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec4 .sec4__lists--li ._item02 {
    max-width: 10rem;
    margin-left: auto;
  }
}
.frontLoader .sec4 .sec4__lists--li ._item03 {
  max-width: 9.6875rem;
  margin-top: 1.25rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec4 .sec4__lists--li ._item03 {
    max-width: 18.75rem;
    margin-top: 2rem;
  }
}
.frontLoader .sec4 .sec4__lists--li ._item04 {
  max-width: 5.625rem;
  margin-top: 0.375rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec4 .sec4__lists--li ._item04 {
    max-width: 10.0625rem;
    margin-top: 0.75rem;
  }
}
.frontLoader .sec4 .sec4__lists--li ._item05 {
  max-width: 5.625rem;
  margin-top: 0.625rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec4 .sec4__lists--li ._item05 {
    max-width: 10.625rem;
    margin-top: 0;
  }
}
.frontLoader .sec4 .sec4__lists--li ._item06 {
  max-width: 5.625rem;
  margin-top: 0.375rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec4 .sec4__lists--li ._item06 {
    max-width: 9.375rem;
    margin-top: 1.375rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec4 .sec4__lists--li:last-child .sec4__lists--link {
    margin-right: 2.6875rem;
  }
}
.frontLoader .sec4 .sec4__lists--link {
  display: block;
  margin-right: 0.875rem;
  font-size: 0.875rem;
  color: #fff;
  line-height: 1.2;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec4 .sec4__lists--link {
    font-size: 1.5rem;
    margin-right: 6.25rem;
  }
}
.frontLoader .sec4 .sec4__lists--link span {
  display: flex;
  align-items: center;
  background-color: #A20008;
  padding: 0.4375rem 0 0.4375rem 0.625rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec4 .sec4__lists--link span {
    padding: 0.1875rem 0 0.1875rem 1.125rem;
  }
}
.frontLoader .sec4 .sec4__lists--link span svg {
  margin: 0 0 0 0.75rem;
  width: 0.5rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec4 .sec4__lists--link span svg {
    margin: 0.5rem 0 0 0.75rem;
    width: 0.875rem;
  }
}
.frontLoader .sec5 {
  padding-top: 5rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec5 {
    padding-top: 10rem;
  }
}
.frontLoader .sec5 .containerVer3 {
  position: relative;
  padding-top: 4.375rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec5 .containerVer3 {
    padding-top: 6.875rem;
  }
}
.frontLoader .sec5 .title_img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 11.75rem;
  margin-right: calc(50% - 50vw);
}
@media screen and (min-width: 600px) {
  .frontLoader .sec5 .title_img {
    max-width: 16.25rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec5 .title_img {
    max-width: 25rem;
  }
}
@media screen and (min-width: 1500px) {
  .frontLoader .sec5 .title_img {
    max-width: 31.25rem;
  }
}
@media screen and (min-width: 1920px) {
  .frontLoader .sec5 .title_img {
    max-width: 43.125rem;
  }
}
.frontLoader .sec5 h3.en .en_bg {
  top: -1.375rem;
  left: -0.75rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec5 h3.en .en_bg {
    top: -3.75rem;
    left: -5.125rem;
  }
}
.frontLoader .sec5 .sec5__lineup {
  margin-top: 0.75rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec5 .sec5__lineup {
    margin-top: 5.625rem;
  }
}
.frontLoader .sec5 .sec5__lineup--ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem 0.9375rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec5 .sec5__lineup--ul {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2.5rem 1.875rem;
  }
}
.frontLoader .sec5 .sec5__lineup p {
  font-size: 0.75rem;
  line-height: 1.5;
  padding: 0 0.625rem;
  letter-spacing: 0.025em;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec5 .sec5__lineup p {
    font-size: 0.875rem;
    margin-top: 0.625rem;
    padding: 0 1rem;
    letter-spacing: normal;
  }
}
.frontLoader .sec6 {
  padding-top: 2.375rem;
}
@media screen and (min-width: 600px) {
  .frontLoader .sec6 {
    padding-top: 6.875rem;
  }
}
.frontLoader .sec6 .containerVer3 {
  position: relative;
  padding-top: 4.625rem;
}
@media screen and (min-width: 600px) {
  .frontLoader .sec6 .containerVer3 {
    padding-top: 10.625rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec6 .containerVer3 {
    padding-top: 7rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec6 .attachment__title--text {
    width: auto;
  }
}
.frontLoader .sec6 .title_img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 10.625rem;
  margin-right: calc(50% - 50vw);
}
@media screen and (min-width: 600px) {
  .frontLoader .sec6 .title_img {
    max-width: 16.25rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec6 .title_img {
    max-width: 25rem;
    left: 0;
    margin-left: calc(50% - 50vw);
  }
}
@media screen and (min-width: 1920px) {
  .frontLoader .sec6 .title_img {
    max-width: 41.375rem;
  }
}
.frontLoader .sec6 h3.en .en_bg {
  top: -1.375rem;
  left: -0.75rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec6 h3.en .en_bg {
    top: -3.75rem;
    left: -5.125rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec6 .sec6__inner {
    width: 100;
    max-width: 49.3125rem;
    margin-left: auto;
  }
}
.frontLoader .sec6 .sec6__lineup {
  margin-top: 0.75rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec6 .sec6__lineup {
    margin-top: 2.5rem;
  }
}
.frontLoader .sec6 .sec6__lineup--ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem 0.9375rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec6 .sec6__lineup--ul {
    gap: 2.5rem 1.875rem;
  }
}
.frontLoader .sec6 .sec6__lineup p {
  font-size: 0.75rem;
  line-height: 1.5;
  padding: 0 0.625rem;
  letter-spacing: 0.025em;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec6 .sec6__lineup p {
    font-size: 0.875rem;
    margin-top: 0.625rem;
    padding: 0 1rem;
    letter-spacing: normal;
  }
}
.frontLoader .sec7 {
  padding-top: 5rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec7 {
    padding-top: 10rem;
  }
}
.frontLoader .sec7 .containerVer3 {
  position: relative;
  padding-top: 4.6875rem;
}
@media screen and (min-width: 600px) {
  .frontLoader .sec7 .containerVer3 {
    padding-top: 7.5rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec7 .containerVer3 {
    padding-top: 4.1875rem;
  }
}
.frontLoader .sec7 .title_video-box {
  position: relative;
}
.frontLoader .sec7 .title_text {
  position: absolute;
  top: -0.5rem;
  left: 0;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 100;
  font-style: italic;
  font-size: 1.0625rem;
  text-transform: uppercase;
  z-index: 1;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec7 .title_text {
    font-size: 3.75rem;
    top: -2.375rem;
    left: auto;
  }
}
.frontLoader .sec7 .title_img {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: calc(50% - 50vw);
}
.frontLoader .sec7 .title_img ._thumbnail {
  width: 11.6875rem;
  height: 6.8125rem;
}
@media screen and (min-width: 600px) {
  .frontLoader .sec7 .title_img ._thumbnail {
    width: 18.75rem;
    height: 10.9375rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec7 .title_img ._thumbnail {
    width: 25rem;
    height: 14.0625rem;
  }
}
@media screen and (min-width: 1500px) {
  .frontLoader .sec7 .title_img ._thumbnail {
    width: 31.25rem;
    height: 18.3125rem;
  }
}
@media screen and (min-width: 1920px) {
  .frontLoader .sec7 .title_img ._thumbnail {
    width: 41.375rem;
    height: 24.25rem;
  }
}
.frontLoader .sec7 h3.en .en_bg {
  top: -1.375rem;
  left: -0.75rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec7 h3.en .en_bg {
    top: -3.75rem;
    left: -5.125rem;
  }
}
.frontLoader .sec7 .sec7__lineup {
  margin-top: 3.375rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec7 .sec7__lineup {
    margin-top: 5.625rem;
  }
}
.frontLoader .sec7 .sec7__lineup--ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem 0.9375rem;
  margin-top: 2.875rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec7 .sec7__lineup--ul {
    gap: 2.5rem 1.875rem;
    margin-top: 7.6875rem;
  }
}
.frontLoader .sec7 .sec7__lineup--item05 {
  margin-top: 2.75rem;
  overflow-x: scroll;
}
.frontLoader .sec7 .sec7__lineup--item05 .sec7__lineup--item05--scroll {
  width: 52rem;
  height: 100%;
  overflow-x: scroll;
  margin: 0 auto;
}
@media screen and (min-width: 1000px) {
  .frontLoader .sec7 .sec7__lineup--item05 .sec7__lineup--item05--scroll {
    width: 100%;
  }
}
.frontLoader .sec7 .sec7__lineup--item07 {
  max-width: 31.25rem;
  margin: 2.5rem auto 0;
}
.frontLoader .sec7 .sec7__lineup p {
  font-size: 0.75rem;
  line-height: 1.5;
  padding: 0 0.625rem;
  letter-spacing: 0.025em;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec7 .sec7__lineup p {
    font-size: 0.875rem;
    margin-top: 1.125rem;
    padding: 0 1.125rem;
    letter-spacing: normal;
  }
}
.frontLoader .sec7 .sec7__lineup--kit {
  display: flex;
  justify-content: space-between;
  margin-top: 3.75rem;
}
@media screen and (min-width: 600px) {
  .frontLoader .sec7 .sec7__lineup--kit {
    justify-content: flex-start;
    margin-top: 5rem;
  }
}
.frontLoader .sec7 .sec7__lineup--kit--text h4 {
  font-size: 1.125rem;
  background-color: #A20008;
  padding: 0.625rem 0;
  text-align: center;
  max-width: 12.25rem;
  font-weight: 100;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec7 .sec7__lineup--kit--text h4 {
    font-size: 1.5rem;
    width: 100%;
    max-width: 21.6875rem;
    padding: 0.625rem;
  }
}
.frontLoader .sec7 .sec7__lineup--kit--text p {
  font-size: 0.875rem;
  line-height: 2;
  margin-top: 1.25rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec7 .sec7__lineup--kit--text p {
    font-size: 1.125rem;
    margin-top: 1rem;
    padding-left: 1.625rem;
  }
}
.frontLoader .sec7 .sec7__lineup--kit--img {
  width: 9.125rem;
  margin-top: 4.8125rem;
}
@media screen and (min-width: 600px) {
  .frontLoader .sec7 .sec7__lineup--kit--img {
    margin-left: 0.625rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec7 .sec7__lineup--kit--img {
    width: 11.875rem;
    margin-top: 0;
    margin-left: 2.375rem;
  }
}
.frontLoader .sec8 {
  padding-top: 4.75rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec8 {
    padding-top: 6.875rem;
  }
}
.frontLoader .sec8 .containerVer3 {
  position: relative;
  padding-top: 4.625rem;
}
@media screen and (min-width: 600px) {
  .frontLoader .sec8 .containerVer3 {
    padding-top: 7.5rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec8 .containerVer3 {
    padding-top: 7rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec8 .attachment__title--text {
    width: auto;
  }
}
.frontLoader .sec8 .title_img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 11.25rem;
  margin-right: calc(50% - 50vw);
}
@media screen and (min-width: 600px) {
  .frontLoader .sec8 .title_img {
    max-width: 16.25rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec8 .title_img {
    max-width: 25rem;
    margin-left: calc(50% - 50vw);
    left: 0;
  }
}
@media screen and (min-width: 1920px) {
  .frontLoader .sec8 .title_img {
    max-width: 41.375rem;
  }
}
.frontLoader .sec8 h3.en .en_bg {
  top: -1.375rem;
  left: -0.75rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec8 h3.en .en_bg {
    top: -3.75rem;
    left: -5.125rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec8 .sec8__inner {
    width: 100;
    max-width: 49.3125rem;
    margin-left: auto;
  }
}
.frontLoader .sec8 .sec8__lineup {
  margin-top: 0.75rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec8 .sec8__lineup {
    margin-top: 2.5rem;
  }
}
.frontLoader .sec8 .sec8__lineup--ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem 0.9375rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec8 .sec8__lineup--ul {
    gap: 2.5rem 1.875rem;
  }
}
.frontLoader .sec8 .sec8__lineup--ulVer2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem 0.9375rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec8 .sec8__lineup--ulVer2 {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2.5rem 1.875rem;
    padding-top: 1.875rem;
  }
}
.frontLoader .sec8 .sec8__lineup p {
  font-size: 0.75rem;
  line-height: 1.5;
  padding: 0 0.625rem;
  letter-spacing: 0.025em;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec8 .sec8__lineup p {
    font-size: 0.875rem;
    margin-top: 0.625rem;
    padding: 0 1rem;
    letter-spacing: normal;
  }
}
.frontLoader .sec9 {
  padding-top: 1.75rem;
}
@media screen and (min-width: 600px) {
  .frontLoader .sec9 {
    padding-top: 7.5rem;
  }
}
.frontLoader .sec9 .containerVer3 {
  position: relative;
  padding-top: 6.25rem;
}
@media screen and (min-width: 600px) {
  .frontLoader .sec9 .containerVer3 {
    padding-top: 10rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec9 .containerVer3 {
    padding-top: 6.875rem;
  }
}
.frontLoader .sec9 .title_img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 11.75rem;
  margin-right: calc(50% - 50vw);
}
@media screen and (min-width: 600px) {
  .frontLoader .sec9 .title_img {
    max-width: 16.25rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec9 .title_img {
    max-width: 25rem;
  }
}
@media screen and (min-width: 1920px) {
  .frontLoader .sec9 .title_img {
    max-width: 42.0625rem;
  }
}
.frontLoader .sec9 .sec9__lineup {
  margin-top: 0.75rem;
  width: 100%;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec9 .sec9__lineup {
    margin-top: 5.625rem;
    max-width: 49.375rem;
    margin-top: 2.3125rem;
  }
}
.frontLoader .sec9 .sec9__lineup--ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem 0.9375rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec9 .sec9__lineup--ul {
    gap: 2.5rem 1.875rem;
  }
}
.frontLoader .sec9 .sec9__lineup p {
  font-size: 0.75rem;
  line-height: 1.5;
  padding: 0 0.625rem;
  letter-spacing: 0.025em;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec9 .sec9__lineup p {
    font-size: 0.875rem;
    margin-top: 0.625rem;
    padding: 0 1rem;
    letter-spacing: normal;
  }
}
.frontLoader .sec10 {
  padding-top: 3.75rem;
}
@media screen and (min-width: 600px) {
  .frontLoader .sec10 {
    padding-top: 9.375rem;
  }
}
.frontLoader .sec10 .containerVer3 {
  position: relative;
  padding-top: 4.625rem;
}
@media screen and (min-width: 600px) {
  .frontLoader .sec10 .containerVer3 {
    padding-top: 7.5rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec10 .containerVer3 {
    padding-top: 7rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec10 .attachment__title--text {
    width: auto;
  }
}
.frontLoader .sec10 .title_img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 11.75rem;
  margin-right: calc(50% - 50vw);
}
@media screen and (min-width: 600px) {
  .frontLoader .sec10 .title_img {
    max-width: 16.25rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec10 .title_img {
    max-width: 25rem;
    margin-left: calc(50% - 50vw);
    left: 0;
  }
}
@media screen and (min-width: 1920px) {
  .frontLoader .sec10 .title_img {
    max-width: 41.375rem;
  }
}
.frontLoader .sec10 h3.en .en_bg {
  top: -1.375rem;
  left: -0.75rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec10 h3.en .en_bg {
    top: -3.75rem;
    left: -5.125rem;
  }
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec10 .sec10__inner {
    width: 100;
    max-width: 49.3125rem;
    margin-left: auto;
  }
}
.frontLoader .sec10 .sec10__lineup {
  margin-top: 0.75rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec10 .sec10__lineup {
    margin-top: 2.5rem;
  }
}
.frontLoader .sec10 .sec10__lineup--ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem 0.9375rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec10 .sec10__lineup--ul {
    gap: 2.5rem 1.875rem;
  }
}
.frontLoader .sec10 .sec10__lineup p {
  font-size: 0.75rem;
  line-height: 1.5;
  padding: 0 0.625rem;
  letter-spacing: 0.025em;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec10 .sec10__lineup p {
    font-size: 0.875rem;
    margin-top: 0.625rem;
    padding: 0 1rem;
    letter-spacing: normal;
  }
}
.frontLoader .sec11 {
  padding-top: 7.5rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec11 {
    padding-top: 10.375rem;
  }
}
.frontLoader .sec11 .lead {
  text-align: left;
  line-height: 2;
  letter-spacing: 0.3;
}
.frontLoader .sec11 .lead h2 {
  font-size: 2.5rem;
  font-style: italic;
  display: flex;
  align-items: center;
  line-height: 1;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec11 .lead h2 {
    font-size: 3.75rem;
  }
}
.frontLoader .sec11 .lead h2 .line {
  display: block;
  width: 10rem;
  background-color: #fff;
  height: 0.0625rem;
  margin-left: 0.8125rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec11 .lead h2 .line {
    width: 18.75rem;
    margin-left: 1.625rem;
  }
}
.frontLoader .sec11 .lead .jp {
  font-size: 1.125rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec11 .lead .jp {
    font-size: 1.5rem;
  }
}
.frontLoader .sec11 .sec11__ul {
  margin-top: 1.5625rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec11 .sec11__ul {
    margin-top: 1.875rem;
    padding: 0 1.25rem;
  }
}
.frontLoader .sec11 .sec11__li:not(:first-child) {
  margin-top: 1.375rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec11 .sec11__li:not(:first-child) {
    margin-top: 2.5rem;
  }
}
.frontLoader .sec11 .sec11__dt {
  font-size: 1.125rem;
  background-color: #A20008;
  padding: 0.625rem 1.25rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec11 .sec11__dt {
    font-size: 1.5rem;
    padding: 0.8125rem 2.5rem;
  }
}
.frontLoader .sec11 .sec11__dd {
  font-size: 0.875rem;
  line-height: 2;
  border-bottom: solid 0.0625rem #A20008;
  border-left: solid 0.0625rem #A20008;
  border-right: solid 0.0625rem #A20008;
  padding: 0.8125rem 1.25rem;
}
@media screen and (min-width: 1200px) {
  .frontLoader .sec11 .sec11__dd {
    font-size: 1.25rem;
    padding: 0.8125rem 2.5rem;
  }
}

.mx--full {
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
}

.ml--full {
  margin-left: calc(50% - 50vw);
}

.mr--full {
  margin-right: calc(50% - 50vw);
}

.px--full {
  padding-right: calc(50vw - 50%);
  padding-left: calc(50vw - 50%);
}

.pl--full {
  padding-left: calc(50vw - 50%);
}

.pr--full {
  padding-right: calc(50vw - 50%);
}

html {
  scroll-behavior: smooth;
}

.frontLoaderLower .img__Banner {
  margin-top: 0 !important;
}

.frontLoaderLower_page h2,
.frontLoaderLower_page h3,
.frontLoaderLower_page h4,
.frontLoaderLower_page p,
.frontLoaderLower_page dl,
.frontLoaderLower_page dd,
.frontLoaderLower_page ul {
  margin: 0;
  padding: 0;
}
.frontLoaderLower_page img {
  width: 100%;
  max-width: 100%;
  height: auto;
}
.frontLoaderLower_page .modalTarget {
  overflow: hidden;
}
.frontLoaderLower_page .modalTarget img {
  transition: transform 0.5s ease;
}
.frontLoaderLower_page .modalTarget:hover img {
  transform: scale(1.05);
}
.frontLoaderLower_page .modal-open {
  padding: 0;
}
.frontLoaderLower_page .modal-close svg {
  fill: #fff;
  transition: fill 0.3s ease;
}
.frontLoaderLower_page .modal-close:hover svg {
  fill: #a20008;
}

.frontLoaderLower_page {
  background-color: #000;
  color: #fff;
  overflow: hidden;
}
.frontLoaderLower_page .loaderLowerWrap {
  position: relative;
  max-width: 78.75rem;
  margin: 0 auto;
  padding: 4.375rem 0.625rem;
}
@media screen and (min-width: 769px) {
  .frontLoaderLower_page .loaderLowerWrap {
    padding: 7.5rem 0.625rem 10rem;
  }
}
.frontLoaderLower_page .frontLoaderLower_page_inner {
  position: relative;
}
.frontLoaderLower_page .bgImg {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}
.frontLoaderLower_page .bgImg .pc_only {
  display: none;
}
@media screen and (min-width: 769px) {
  .frontLoaderLower_page .bgImg .pc_only {
    display: block;
  }
}
@media screen and (min-width: 769px) {
  .frontLoaderLower_page .bgImg .sp_only {
    display: none;
  }
}
.frontLoaderLower_page .loaderLower_section_lead .sectionTitle-en,
.frontLoaderLower_page .loaderLower_section_lead .sectionTitle-ja {
  letter-spacing: 0.05em;
}
.frontLoaderLower_page .loaderLower_section_lead .sectionTitle-en {
  display: block;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 3.75rem;
  font-style: italic;
  font-weight: 100;
  line-height: 1;
  text-transform: uppercase;
}
@media screen and (min-width: 769px) {
  .frontLoaderLower_page .loaderLower_section_lead .sectionTitle-en {
    font-size: 6.25rem;
  }
}
.frontLoaderLower_page .loaderLower_section_lead .sectionTitle-ja {
  display: flex;
  align-items: center;
  font-size: 0.9375rem;
  font-weight: 300;
  line-height: 1;
  margin-top: 1rem;
  padding-left: 0.75rem;
}
@media screen and (min-width: 769px) {
  .frontLoaderLower_page .loaderLower_section_lead .sectionTitle-ja {
    font-size: 1.5rem;
  }
}
.frontLoaderLower_page .loaderLower_section_lead .sectionTitle-ja .text {
  display: block;
  flex-shrink: 0;
  margin-right: 1.75rem;
}
.frontLoaderLower_page .loaderLower_section_lead .sectionTitle-ja .line {
  display: block;
  width: 100%;
  height: 0.0625rem;
  background-color: #fff;
  margin-right: calc(50% - 50vw);
}
.frontLoaderLower_page .loaderLower_section_contents {
  margin: 3.75rem auto 0;
  padding: 0 0.75rem;
  max-width: 62.5rem;
}
@media screen and (min-width: 769px) {
  .frontLoaderLower_page .loaderLower_section_contents {
    margin: 6rem auto 0;
  }
}
@media screen and (min-width: 769px) {
  .frontLoaderLower_page .loaderLower_section_contents_header {
    display: flex;
    align-items: flex-start;
  }
}
.frontLoaderLower_page .contents_title_en,
.frontLoaderLower_page .contents_title_ja {
  letter-spacing: 0.03em;
  display: block;
}
.frontLoaderLower_page .contents_title_en {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 2.5rem;
  font-style: italic;
  font-weight: 100;
  text-transform: uppercase;
}
@media screen and (min-width: 769px) {
  .frontLoaderLower_page .contents_title_en {
    font-size: 3.75rem;
  }
}
.frontLoaderLower_page .contents_title_ja {
  font-size: 1.125rem;
  font-weight: 100;
}
.frontLoaderLower_page .contents_lead {
  font-size: 0.875rem;
  font-weight: 300;
  letter-spacing: 0.03em;
  line-height: 2;
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .frontLoaderLower_page .contents_lead {
    font-size: 1.125rem;
    margin-top: 0;
    margin-left: 7.5rem;
  }
}
.frontLoaderLower_page .movie_lists {
  margin-top: 2.5rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2.5rem;
}
@media screen and (min-width: 769px) {
  .frontLoaderLower_page .movie_lists {
    margin-top: 5rem;
    grid-template-columns: repeat(2, 1fr);
  }
}
.frontLoaderLower_page .movie_card_detail {
  margin-top: 0.75rem;
  padding-left: 0.75rem;
}
@media screen and (min-width: 769px) {
  .frontLoaderLower_page .movie_card_detail {
    padding-left: 1.25rem;
  }
}
.frontLoaderLower_page .movie_card_title {
  font-size: 0.875rem;
  font-weight: 300;
  letter-spacing: 0.03em;
  line-height: 1.6;
}
@media screen and (min-width: 769px) {
  .frontLoaderLower_page .movie_card_title {
    font-size: 1.125rem;
  }
}
.frontLoaderLower_page .movie_card_type {
  margin-top: 0.75rem;
  font-size: 0.75rem;
  font-weight: 300;
  letter-spacing: 0.03em;
}
@media screen and (min-width: 769px) {
  .frontLoaderLower_page .movie_card_type {
    font-size: 1rem;
    margin-top: 1rem;
    padding-left: 1.25rem;
  }
}
.frontLoaderLower_page .movie_card_type dt {
  margin-bottom: 0.25rem;
}
.frontLoaderLower_page .movie_card_type dd {
  font-weight: 100;
}
.frontLoaderLower_page .movie_card_type dd + dd {
  margin-top: 0.1875rem;
}
@media screen and (min-width: 769px) {
  .frontLoaderLower_page .movie_card_type dd + dd {
    margin-top: 0.25rem;
  }
}

.category-page .category-page__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.category-page .category-page__entries {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
  margin-top: 3.625rem;
  padding: 0 1.25rem;
  box-sizing: border-box;
}
@media screen and (min-width: 769px) {
  .category-page .category-page__entries {
    padding: 0;
    max-width: 55rem;
  }
}
.category-page .category-page__pagenation {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
}

.categories {
  width: 100%;
  background: #EEEEEE;
  padding: 2.625rem 0.625rem 0;
  box-sizing: border-box;
}
@media screen and (min-width: 769px) {
  .categories {
    padding: 0;
    padding-top: 3.75rem;
  }
}
.categories .categories__list {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.625rem 1rem;
  background: #fff;
  margin: 0 auto;
  padding: 1.875rem 1.25rem 1.25rem;
}
@media screen and (min-width: 769px) {
  .categories .categories__list {
    max-width: 75rem;
    display: flex;
    justify-content: space-between;
    gap: 0.625rem 0.5rem;
    padding: 4.25rem 6.25rem 1.25rem;
  }
}
.categories .categories__link {
  display: block;
  padding-left: 1.125rem;
  padding-bottom: 0.625rem;
  position: relative;
  font-weight: 700;
}
@media screen and (min-width: 769px) {
  .categories .categories__link {
    padding-bottom: 1.875rem;
  }
}
.categories .categories__link::before {
  display: flex;
  justify-content: center;
  align-items: center;
  content: ">";
  position: absolute;
  top: 0.5em;
  left: 0;
  width: 0.8125rem;
  height: 0.8125rem;
  background: #D3000A;
  color: #fff;
  border-radius: 100%;
  font-size: 0.625rem;
}
.categories .categories__link::after {
  display: block;
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0.0625rem;
  background: #D3000A;
  transition: width 0.3s ease-out, color 0.3s ease-out;
}
.categories .categories__link.is-active, .categories .categories__link:hover {
  color: #D3000A;
}
.categories .categories__link.is-active::after, .categories .categories__link:hover::after {
  width: 100%;
}

.entry {
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 0.125rem solid #EEEEEE;
}
.entry .entry__header {
  display: grid;
  gap: 0.625rem 5rem;
  grid-template-columns: 1fr;
}
@media screen and (min-width: 769px) {
  .entry .entry__header {
    grid-template-columns: 7.75rem 1fr;
  }
}
.entry .entry__meta {
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;
}
@media screen and (min-width: 769px) {
  .entry .entry__meta {
    flex-wrap: initial;
    flex-direction: column;
  }
}
.entry .entry__meta .entry__date {
  font-size: 1rem;
  font-weight: 700;
}
.entry .entry__meta .entry__categories {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
}
.entry .entry__meta .entry__categories .entry__category {
  display: inline-block;
  border: 0.0625rem solid #333;
  text-align: center;
  width: 7.75rem;
}
.entry .entry__title {
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
}
.NewsList__item {
  width: 100%;
  max-width: 75rem;
  margin: 0 auto;
  padding: 0 1.25rem 2.5rem;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  background: #e6e6e6;
}
@media screen and (min-width: 769px) {
  .NewsList__item {
    padding: 0;
  }
}
.NewsList__item .NewsList__link {
  position: relative;
  color: #707070;
  font-size: 1rem;
  padding-right: 1.5em;
}
.NewsList__item .NewsList__link::before {
  display: flex;
  justify-content: center;
  align-items: center;
  content: ">";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 0.8125rem;
  height: 0.8125rem;
  border-radius: 100%;
  font-size: 0.625rem;
  color: #fff;
  background: #D3000A;
}

.NewsWidthControl .NewsList > ul li:last-child {
  border-bottom: 0.125rem solid #fff;
}

.lower {
  display: block;
}

._kusakari-banner {
  margin: 2.5rem 0;
  padding: 0 1.25rem;
}
@media screen and (min-width: 769px) {
  ._kusakari-banner {
    margin: 5rem 0;
  }
}
._kusakari-banner a {
  display: block;
  max-width: 56.3125rem;
  margin: 0 auto;
  transition: all 0.5s ease;
}
._kusakari-banner img {
  width: 100%;
  max-width: 100%;
}
._kusakari-banner:hover {
  opacity: 0.5;
}

.main-menu font {
  pointer-events: none;
}