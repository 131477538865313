@use '../../../base/vars'as *;

.lowerMv {
    position: relative;
    width: 100%;
    aspect-ratio: calc(390/180);

    @include media(sm) {
        aspect-ratio: calc(1920/270);
    }

    .img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
    }

    .lowerMv__inner {
        width: 100%;
        display: flex;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .lowerMv__title {
        display: flex;
        flex-direction: column;
        font-weight: 700;
        font-size: rem(27);
        margin-left: rem(20);

        @include media(sm) {
            font-size: rem(35);
        }

        .lowerMv__subTitle {
            font-size: rem(15);
            color: $red;
            text-transform: uppercase;
        }
    }

}
