@use '../../base/vars'as *;

html {
    scroll-behavior: smooth;
}

//*------------------------------------*//
//    変数設定
//*------------------------------------*//
$roboto: 'Roboto Condensed',
sans-serif;
$zenKaku_antique: 'Zen Kaku Gothic Antique',
sans-serif;
$zen_old: 'Zen Old Mincho',
serif;

$red: #D3000A;
$red02: #A20008;

$pb: 'lg';


//*------------------------------------*//
//    共通
//*------------------------------------*//

.frontLoaderLower {

    .img__Banner {
        margin-top: 0 !important;
    }
}

.frontLoaderLower_page {

    h2,
    h3,
    h4,
    p,
    dl,
    dd,
    ul {
        margin: 0;
        padding: 0;
    }

    img {
        width: 100%;
        max-width: 100%;
        height: auto;
    }

    .modalTarget {
        overflow: hidden;
    }

    .modalTarget img {
        transition: transform .5s ease;
    }

    .modalTarget:hover img {
        transform: scale(1.05);
    }

    .modal-open {
        padding: 0;
    }

    .modal-close svg {
        fill: #fff;
        transition: fill .3s ease;
    }

    .modal-close:hover svg {
        fill: #a20008;
    }
}

.frontLoaderLower_page {
    background-color: #000;
    color: $white;
    overflow: hidden;

    .loaderLowerWrap {
        position: relative;
        max-width: rem(1260);
        margin: 0 auto;
        padding: rem(70) rem(10);

        @include media(sm) {
            padding: rem(120) rem(10) rem(160);
        }
    }

    .frontLoaderLower_page_inner {
        position: relative;
    }

    .bgImg {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;

        .pc_only {
            display: none;

            @include media(sm) {
                display: block;
            }
        }

        .sp_only {

            @include media(sm) {
                display: none;
            }
        }
    }

    .loaderLower_section_lead {

        .sectionTitle-en,
        .sectionTitle-ja {
            letter-spacing: 0.05em;
        }

        .sectionTitle-en {
            display: block;
            font-family: $roboto;
            font-size: rem(60);
            font-style: italic;
            font-weight: 100;
            line-height: 1;
            text-transform: uppercase;

            @include media(sm) {
                font-size: rem(100);
            }
        }

        .sectionTitle-ja {
            display: flex;
            align-items: center;
            font-size: rem(15);
            font-weight: 300;
            line-height: 1;
            margin-top: rem(16);
            padding-left: rem(12);

            @include media(sm) {
                font-size: rem(24);
            }
        }

        .sectionTitle-ja .text {
            display: block;
            flex-shrink: 0;
            margin-right: rem(28);
        }

        .sectionTitle-ja .line {
            display: block;
            width: 100%;
            height: rem(1);
            background-color: $white;
            margin-right: calc(50% - 50vw);
        }
    }

    .loaderLower_section_contents {
        margin: rem(60) auto 0;
        padding: 0 rem(12);
        max-width: rem(1000);

        @include media(sm) {
            margin: rem(96) auto 0;
        }
    }

    .loaderLower_section_contents_header {

        @include media(sm) {
            display: flex;
            align-items: flex-start;
        }
    }

    .contents_title_en,
    .contents_title_ja {
        letter-spacing: 0.03em;
        display: block;
    }

    .contents_title_en {
        font-family: $roboto;
        font-size: rem(40);
        font-style: italic;
        font-weight: 100;
        text-transform: uppercase;

        @include media(sm) {
            font-size: rem(60);
        }
    }

    .contents_title_ja {
        font-size: rem(18);
        font-weight: 100;
    }

    .contents_lead {
        font-size: rem(14);
        font-weight: 300;
        letter-spacing: 0.03em;
        line-height: 2;
        margin-top: rem(20);

        @include media(sm) {
            font-size: rem(18);
            margin-top: 0;
            margin-left: rem(120);
        }
    }

    .movie_lists {
        margin-top: rem(40);
        display: grid;
        grid-template-columns: 1fr;
        gap: rem(40);

        @include media(sm) {
            margin-top: rem(80);
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .movie_card_detail {
        margin-top: rem(12);
        padding-left: rem(12);

        @include media(sm) {
            padding-left: rem(20);
        }
    }

    .movie_card_title {
        font-size: rem(14);
        font-weight: 300;
        letter-spacing: 0.03em;
        line-height: 1.6;

        @include media(sm) {
            font-size: rem(18);
        }
    }

    .movie_card_type {
        margin-top: rem(12);
        font-size: rem(12);
        font-weight: 300;
        letter-spacing: 0.03em;

        @include media(sm) {
            font-size: rem(16);
            margin-top: rem(16);
            padding-left: rem(20);
        }

        dt {
            margin-bottom: rem(4);
        }

        dd {
            font-weight: 100;
        }

        dd+dd {
            margin-top: rem(3);

            @include media(sm) {
                margin-top: rem(4);
            }
        }
    }
}
