@use '../../base/vars'as *;

.lower {
    display: block;
}


// 草刈機
._kusakari-banner{
    margin: rem(40) 0;
    padding: 0 rem(20);

    @include media(sm){
        margin: rem(80) 0;
    }

    a{
        display: block;
        max-width: rem(901);
        margin: 0 auto;
        transition: all .5s ease;
    }

    img{
        width: 100%;
        max-width: 100%;
    }

    &:hover{
        opacity: 0.5;
    }
}
