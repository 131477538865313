@use '../base/vars'as *;

/* モーダルを複数設置するためのwrapperクラス */
.modalTarget {
    position: relative;
}


/* モーダルを開くボタン */
.modal-open {
    cursor: pointer;
    border: none;
    outline: none;
    background: transparent;
}


/* モーダルと背景の指定 */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 99999;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
    overflow: auto;
    transition: .3s;
    box-sizing: border-box;
    padding: 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* クラスが追加された時の指定 */
.modal.is-active {
    opacity: 1;
    visibility: visible;
}


/* モーダル内側の指定 */
.modal-container {
    position: relative;
    margin: 0 auto;
}

/* モーダルを閉じるボタンの指定 */
.modal-close {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.modal-close.modal-close-frontLoader{
    top: rem(-40);
    right: 0;
    width: 20px;

    @include media(sm){
        top: rem(-50);
        width: rem(30);
    }
}


/* モーダルのコンテンツ部分の指定 */
.modal-content {
    flex: 1;
}


/* モーダル内の動画 */
.iframe-wrapper {
    width: 80vw;
    height: 45vw;

    @include media(sm){
        width: 70vw;
        height: 40vw;
    }
  }

.iframe-wrapper iframe {
    width: 100%;
    height: 100%;
}

