@use '../../base/vars'as *;

.instruction-manual-page {
    padding-top: 60px;

    @include media(sm) {
        padding-top: 100px;
    }

    h1 {
        padding: 0 20px;
    }
}

.SearchFormTab {
    .form__name {
        font-weight: 700;
    }

    #form01 {
        .form__name {
            color: $green;
            border-bottom-color: rgba($green, .5);
        }

        .submitBtn {
            background-color: $green;
        }
    }

    #form02 {
        .BigCatregory:first-of-type {
            padding-top: 40px;
            border-top: 5px solid $green_l;
        }

        .form__name {
            color: $brown;
            border-bottom-color: rgba($brown, .5);
        }

        .submitBtn {
            background-color: $brown;
        }
    }

    .BigCatregory {
        >p {
            font-size: rem(18);
        }

        label {
            margin: 0;
        }
    }

    button[type="submit"],
    .submitBtn,
    .clearBtn {
        cursor: pointer;
        transition: opacity .8s ease-out;

        &:hover {
            opacity: .7;
        }
    }
}

.radioList {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: rem(8) rem(20);
    line-height: 2;

    li {
        label {
            display: flex;
            align-items: center;

            span {
                margin-left: 0.5em;
            }
        }

        input[type="radio"] {
            margin: 0;
        }
    }
}

.SearchResultWrap {

    &:not([plTable]),
    &:not([oiTable]) {
        th {
            &:first-child {}

            &:nth-child(2) {
                width: 300px;
            }

            &:nth-child(3) {
                width: 20em;
            }

            &:last-child {
                width: 80px;
            }
        }
    }

    &.oiTable {
        th {
            &:nth-child(2) {}

            &:last-child {
                width: 120px;
            }
        }
    }

    td {
        text-align: left;

        &.PartsListDL {
            text-align: center;
        }
    }
}

#searchResults {
    padding-top: 80px;
}

.buttonWrapper {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 25px 0;
}

.SearchFormTab {

    .clearBtn,
    .submitBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 200px;
        height: 40px;
        font-size: 17px;
        border: 0;
        color: white;
    }

    .submitBtn {
        cursor: pointer;
        background: #86C03F;
    }

    .clearBtn {
        background: #aaa !important;
    }
}

.SearchFormTab button[type="submit"] {
    margin: 0;
}
