@use '../../../base/vars'as *;


.NewsList__item {
    width: 100%;
    max-width: rem(1200);
    margin: 0 auto;
    padding: 0 rem(20) rem(40);
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    background: $gray_sub;


    @include media(sm) {
        padding: 0;
    }

    .NewsList__link {
        position: relative;
        color: $gray_d;
        font-size: rem(16);
        padding-right: 1.5em;

        &::before {
            display: flex;
            justify-content: center;
            align-items: center;
            content: ">";
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: rem(13);
            height: rem(13);
            border-radius: 100%;
            font-size: rem(10);
            color: $white;
            background: $red;
        }
    }
}

.NewsWidthControl .NewsList>ul li:last-child {
    border-bottom: rem(2) solid $white;
}
