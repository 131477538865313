@use '../base/vars'as *;

.swiper-container {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    position: relative;

    img,
    video{
        width: 100%;
        max-width: 100%;
        display: block;
        object-fit: cover;
    }

    .swiper-slide-wrap{
        height: 100%;
    }

    .swiper-slide-wrap p{
        margin: 0;
        padding: 0;
    }

    .video{
        height: 100%;
    }

    .swiper-button-prev,
    .swiper-button-next {
        color: var(--swiperButton) !important;
    }

    .not_url{
        pointer-events: none;
    }

    .sp_only {
        display: block;

        @include media(sm){
            display: none;
        }
    }

    .pc_only {
        display: none;

        @include media(sm){
            display: block;
        }
    }

    .item_hidden{
        display: none;
    }
}

.swiper-pagination{

    .swiper-pagination-bullet{
        background-color: var(--paginationColor);
    }

    .swiper-pagination-bullet-active{
        background-color: var(--paginationColorActive) !important;
    }
}

.no_swiper_img{

    img{
        width: 100%;
        max-width: 100%;
    }
}



