@use '../../../base/vars'as *;

.categories {
    width: 100%;
    background: $gray;
    padding: rem(42) rem(10) 0;
    box-sizing: border-box;

    @include media(sm) {
        padding: 0;
        padding-top: rem(60);
    }

    .categories__list {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: rem(10) rem(16);
        background: $white;
        margin: 0 auto;
        padding: rem(30) rem(20) rem(20);

        @include media(sm) {
            max-width: rem(1200);
            display: flex;
            justify-content: space-between;
            gap: rem(10) rem(8);
            padding: rem(68) rem(100) rem(20);
        }
    }

    .categories__item {}

    .categories__link {
        display: block;
        padding-left: rem(18);
        padding-bottom: rem(10);
        position: relative;
        font-weight: 700;

        @include media(sm) {
            padding-bottom: rem(30);
        }

        &::before {
            display: flex;
            justify-content: center;
            align-items: center;
            content: ">";
            position: absolute;
            top: .5em;
            left: 0;
            width: rem(13);
            height: rem(13);
            background: $red;
            color: $white;
            border-radius: 100%;
            font-size: rem(10);
        }

        &::after {
            display: block;
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: rem(1);
            background: $red;
            transition: width .3s ease-out, color .3s ease-out;
        }

        &.is-active,
        &:hover {
            color: $red;

            &::after {
                width: 100%;
            }
        }

    }
}
