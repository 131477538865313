@use '../base/vars'as *;

.un_catalog_title,
.un_catalog_lead{
    text-align: center;
    padding: 0 rem(20);
}

.bl_catalog{
    margin-top: rem(50);
    padding: 0 rem(20);

    @include media(sm){
        margin-top: rem(100);
    }
}

.bl_catalog_lists{
    display: grid;
    grid-template-columns: 1fr;
    gap: rem(50);
    max-width: rem(375);
    margin: rem(30) auto;
    text-align: center;

    @include media(sm){
        grid-template-columns: 1fr 1fr;
        max-width: rem(769);
        text-align: left;
        gap: rem(60) rem(20);
    }

    @include media(md){
        max-width: 100%;
        margin: rem(30) 0 0;
    }
}

.bl_catalog_lists_item{


    @include media(md){
        display: flex;
    }
}

.bl_catalog_lists_item_ttl{
    margin: 0 auto;
    width: 100%;
    max-width: rem(210);

    @include media(sm){
        margin: 0;
    }

    img{
        width: 100%;
        max-width: rem(210);
    }

    a{
        transition: opacity .3s ease;
        cursor: pointer;

        &:hover{
            opacity: .5;
        }
    }
}


.bl_catalog_lists{
    padding: 0;
}

.bl_catalog_lists_item_detail{
    margin-top: rem(10);

    @include media(md){
        margin-top: 0;
        padding-left: rem(30);
    }

    & + & {
        margin-top: rem(20);

        @include media(sm){
            margin-top: rem(30);
        }
    }
}

.bl_catalog_lists_item_detail_ttl{
    margin: 0;
    font-size: rem(18);

    @include media(sm){
        font-size: rem(20);
    }
}

.bl_catalog_lists_item_detail_lists{
    font-size: rem(16);
    padding: 0;
    margin-top: rem(6);

    @include media(sm){
        font-size: rem(18);
    }

    @include media(md){
        margin-top: rem(10);
    }

    li + li{
        margin-top: rem(10);

        @include media(sm){
            margin-top: rem(12);
        }
    }

    .bl_catalog_lists_item_detail_notes{
        font-size: rem(14);
        margin-top: rem(20);
    }
}
