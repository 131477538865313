@use "sass:math";

///*------------------------------------*//
//    Fonts
//*------------------------------------*//
$font-family: "Helvetica Neue",
Arial,
"Hiragino Kaku Gothic ProN",
"Hiragino Sans",
"BIZ UDPGothic",
Meiryo,
sans-serif;
$line-height: 1.5;




///*------------------------------------*//
//    Colors
//*------------------------------------*//
$main-color: #fff;
$accent-color: #f00;
$font-color: #000;
$base-color: #fff;
$bg-color: #fff;
$green: #186747;
$green_l: #86C03F;
$brown: #746113;
$red:#C7000A;
$gray:#EEEEEE;
$gray_sub:#e6e6e6;
$gray_d:#707070;
$white: #fff;
$black: #333;

///*------------------------------------*//
//   z-index
//*------------------------------------*//
$bg: 1;
$main: 10;
$menu: 100;
$header: 1000;
$modal: 10000;


///*------------------------------------*//
//     breakpoints
//*------------------------------------*//
$breakpoints: (xs: 'screen and (min-width: 600px)',
    sm: 'screen and (min-width: 769px)',
    md: 'screen and (min-width: 1000px)',
    lg: 'screen and (min-width: 1200px)',
    xl: 'screen and (min-width: 1366px)',
    xxl: 'screen and (min-width: 1500px)',
    xxll: 'screen and (min-width: 1800px)',
    xxxl: 'screen and (min-width: 1920px)',
    sm-max: 'screen and (max-width: 399px)',
) !default;


///*------------------------------------*//
//     Mixin
//*------------------------------------*//

//メディアクエリ挿入
@mixin media($breakpoint) {
    @media #{map-get($breakpoints, $breakpoint)} {
        @content;
    }
}

//フォントサイズ計算
@function rem($size, $base: 16) {
    @return math.div($size, $base)+rem;
}

//pxをvwに変換するfunction
@function vw($size, $viewport: $wrapper) {
    $rate: calc(100 / $viewport);
    @return $rate * $size * 1vw;
}

//hover
@mixin hover {
    transition: all .5s ease-out;

    &:focus {
        @content;
    }

    @media (hover: hover) {
        &:hover {
            @content;
        }
    }
}

// 親要素からはみ出させる
@mixin mx-full {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
}

@mixin ml-full {
    margin-left: calc(50% - 50vw);
}

@mixin mr-full {
    margin-right: calc(50% - 50vw);
}

@mixin px-full {
    padding-left: calc(50vw - 50%);
    padding-right: calc(50vw - 50%);
}

@mixin pl-full {
    padding-left: calc(50vw - 50%);
}

@mixin pr-full {
    padding-right: calc(50vw - 50%);
}

// 中央寄せ
@mixin position {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// 左右中央
@mixin position-x {
    left: 50%;
    transform: translateX(-50%);
}

// 上下中央
@mixin position-y {
    top: 50%;
    transform: translateY(-50%);
}
