@use '../../../base/vars'as *;

.entry {
    width: 100%;
    padding-bottom: rem(16);
    border-bottom: rem(2) solid $gray;

    .entry__header {
        display: grid;
        gap: rem(10) rem(80);
        grid-template-columns: 1fr;

        @include media(sm) {
            grid-template-columns: rem(124) 1fr;
        }
    }

    .entry__meta {
        display: flex;
        flex-wrap: wrap;
        gap: rem(10);

        @include media(sm) {
            flex-wrap: initial;
            flex-direction: column;
        }

        .entry__date {
            font-size: rem(16);
            font-weight: 700;
        }

        .entry__categories {
            display: flex;
            flex-wrap: wrap;
            gap: rem(4);

            @include media(sm) {}

            .entry__category {
                display: inline-block;
                border: rem(1) solid $black;
                text-align: center;
                width: rem(124);

                @include media(sm) {}
            }
        }
    }

    .entry__title {
        font-size: rem(16);
        font-weight: 400;
        margin: 0;

        .entry__link {}
    }
}
