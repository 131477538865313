@use './vars'as *;

// ホバー
.hover {
    transition: opacity .3s ease-out;

    @include hover {
        opacity: .8;
    }
}

.list-style_none{
    list-style: none;
}

._flex{
    display: flex;
}

._block{
    display: block;
}

.pointer_events_none{
    pointer-events: none;
}



._no-image{
    position: relative;
    padding: 0 !important;

    img{
        width: 100% !important;
        height: 100% !important;
    }

    ._textBox{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
        width: 100%;

        p{
            font-size: rem(18);
            margin: 0;

            @include media(sm){
                font-size: rem(26);
            }
        }

        p:first-child{
            font-size: rem(36);

            @include media(sm){
                font-size: rem(40);
            }
        }


        p + p{
            margin-top: rem(20);

            @include media(sm){
                margin-top: rem(30);
            }
        }
    }
}
